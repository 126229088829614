export const licenseColumns = [{
        name: "许可项类型",
        require: true,
        key: "subs_plan_item_type_code",
        width: "15%",
    },
    {
        name: "许可项编码",
        require: true,
        key: "subs_plan_item_code",
        width: "15%",
    },
    {
        name: "许可项名称",
        require: true,
        key: "subs_plan_item_name",
        width: "15%",
    },
    {
        name: "许可项描述",
        require: false,
        key: "subs_plan_item_desc",
        width: "15%",
    },
    {
        name: "授权值",
        require: true,
        key: "item_auth_value",
        width: "28%",
    },
    {
        name: "告警阈值（余值）",
        require: true,
        key: "item_alert_value",
        width: "12%",
    },
]
export const historyColumns = [{
        name: "订阅方案编码",
        require: false,
        get: (_)=> (_?.subs_plan || {})?.subs_plan_code,
        width: "20%",
    },
    {
        name: "订阅方案名称",
        require: false,
        get: (_)=> (_?.subs_plan_ver || {})?.subs_plan_name,
        width: "20%",
    },
    {
        name: "版本",
        require: false,
        get: (_)=> (_?.subs_plan_ver || {})?.ver_no,
        width: "calc(20% - 60px)",
    },
    {
        name: "订阅时间",
        require: false,
        get: (_)=> (_?.co_info || {})?.created_time,
        width: "20%",
    },
    {
        name: "订阅截止时间",
        require: false,
        get: (_)=> (_?.co_info || {})?.subs_deadline_time,
        width: "20%",
    },
    {
        name: "",
        require: false,
        get: (_)=> (_?.co_info || {})?.is_delete ? <div className="tagDiv">已撤销</div> : '',
        width: "60px",
    }
]