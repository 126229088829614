import "./App.scss";
import MyHeader from "./components/MyHeader";
import { Layout, message, Breadcrumb } from "antd";
import Scrollbars from "react-custom-scrollbars";
import routers from "./router/router";
import Sidebar from "./components/Sidebar";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { useEffect } from "react";
import Api from "./apis/index";
import { useState } from "react";
import { removeToken, setRedirectUrl, setToken } from "./unit";
import { useDispatch, useSelector } from "react-redux";

const { Content } = Layout;

function App() {
  const location = useLocation();

  const [userInfo, setUserInfo] = useState(null);
  const [personalMenus, setPersonalMenus] = useState(null);
  const [licenseName, setLicenseName] = useState(null);
  const [userMenus, setUserMenus] = useState([]);

  const breadcrumbList = useSelector((state) => state.MenuInfo.breadcrumbList);
  const [routerList, setRouterList] = useState([]);
  const [showRouters, setShowRouters] = useState([]);

  useEffect(() => {
    console.log(location);
  }, [location]);

  useEffect(() => {
    getUserInfo();
    console.log(breadcrumbList);
  }, []);

  const initRouters = (menus) => {
    let showList = [];
    const menuItem = menus?.find((_) => _.menu_code === "OPM_PLATFORM");
    let routerList = (menuItem?.children || [])?.map((_) => {
      if (_?.children?.length > 0) {
        _.children = (_?.children || [])?.map((__) => {
          const _routerItem = routers?.find(
            (_____) => _____.menu_name === __.menu_name
          );
          if (!__.menu_url) {
            __.menu_url = _routerItem?.menu_url || null;
          }
          __.element = _routerItem?.element || null;
          if (__.menu_url) {
            showList.push(__);
          }
          return __;
        });
      }
      const routerItem = routers?.find(
        (_____) => _____.menu_name === _.menu_name
      );
      if (!_.menu_url) {
        _.menu_url = routerItem?.menu_url || null;
      }
      _.element = routerItem?.element || null;
      if (_.menu_url) {
        showList.push(_);
      }
      return _;
    });
    setRouterList(routerList);
    setShowRouters(showList);
  };

  //获取项目菜单
  const getUserMenus = () => {
    Api.getMenus().then((res) => {
      console.log("🚀 ~ file: App.js:23 ~ Api.getMenus ~ res:", res);
      if (res.code === 200) {
        const menusItem = res?.data?.find((_) => _.menu_code === "OPM_PLATFORM");
        if (!menusItem) {
          // message.error('未匹配到本项目的目录数据!');
          const findItem = (res.data || [])?.find((i) => i.menu_url);
          //订阅过项目 跳到订阅的项目里
          if (findItem) {
            window.location.href = findItem.menu_url;
          } else {
            //没订阅过 跳到详情页
            window.location.href = localStorage.getItem("sub_url");
          }
          return;
        }
        setUserMenus(res.data);
        initRouters(res.data);
      } else {
        setUserMenus([]);
      }
    });
  };

  // //获取个人设置菜单
  // const getPersonalMenus = () => {
  //   Api.getPersonalMenus().then((res) => {
  //     console.log("🚀 ~ file: App.js:29 ~ Api.getPersonalMenus ~ res:", res);
  //   });
  // };

  //获取用户信息
  const getUserInfo = () => {
    Api.getUserInfo().then((res) => {
      console.log("🚀 ~ file: App.js:34 ~ Api.getUserInfo ~ res:", res);
      if (res.code === 200) {
        setUserInfo(res.data);
        getUserMenus(res.data.co_id);
        // getPersonalMenus();
        getMenuOfPersonal();
      } else {
        logout();
      }
    });
  };

  //获取菜单
  const getMenuOfPersonal = () => {
    Api.getMenuOfPersonal().then((res) => {
      console.log(res);
      if (res.code === 200) {
        setPersonalMenus(res.data?.menu_list);
        setLicenseName(res.data?.license_name);
      } else {
        setPersonalMenus([]);
        setLicenseName(null);
        message.error(res.message);
      }
    });
  };
  //退出
  const logout = () => {
    Api.logoutFunc(window.location.origin + "/login").then((res) => {
      if (res.code === 200) {
        removeToken();
        setRedirectUrl(window.location.href);
        if (res.data.logout_url) {
          window.location.href = res.data.logout_url;
        } else {
          window.location.reload();
        }
      } else {
        message.error(res.message);
      }
    });
  };

  //切换企业成功回调
  const switchEnterpriseSuccessFunc = (data) => {
    console.log("switchEnterpriseSuccessFunc", data);
    setToken(data.token);
    window.location.reload();
  };

  const authCallBack = (type) => {
    console.log("authCallBack", type);
  };

  return (
    <div className="App">
      <Layout className="my-layout">
        <MyHeader
          userMenus={userMenus}
          userInfo={userInfo}
          personalMenus={personalMenus}
          logoutBack={logout}
          getUnreadNoticesFunc={Api.getUnreadNotices}
          getNoticesFunc={Api.getNotices}
          setReadNoticesFunc={Api.setReadNotices}
          deleteNoticesFunc={Api.deleteNotices}
          getUpdateLogList={Api.getUpdateLogList}
          getEnterpriseFunc={Api.getEnterprise}
          switchEnterpriseFunc={Api.switchEnterprise}
          switchEnterpriseCallBack={switchEnterpriseSuccessFunc}
          licenseName={licenseName}
          projectCode="OPM_PLATFORM"
          authCallBack={authCallBack}
          // showNotice={false}
        ></MyHeader>
        <Layout>
          <Sidebar routerList={routerList} userMenus={userMenus}></Sidebar>
          <Content className="my-content">
            <div className="breadcrumbBox">
              <Breadcrumb items={breadcrumbList} />
            </div>
            {/* {element} */}
            <Scrollbars
              className="scrollbars"
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <div className="contentBox">
                {showRouters.length > 0 ? (
                  <Routes key={location.key}>
                    {showRouters.map((item) => (
                      <Route
                        path={item.menu_url}
                        key={item.menu_url}
                        breadcrumbName={item.menu_name}
                        element={
                          <PrivateRoute menu_name={item.menu_name}>
                            {item.element}
                          </PrivateRoute>
                        }
                      />
                    ))}
                    <Route
                      path="/*"
                      element={<Navigate to={showRouters[0]?.menu_url} />}
                    />
                  </Routes>
                ) : null}
              </div>
            </Scrollbars>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
