import { Menu, Layout } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createFromIconfontCN, FormOutlined } from "@ant-design/icons";
import "./index.scss";
import logo from "../../assets/logo.png";
import { ReactSVG } from "react-svg";
import HomeIcon from "../../assets/首页.svg";

const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_3354122_puxmtsyxun9.js",
});

const { Sider } = Layout;

export default function Sidebar(props) {
  const { userMenus } = props;
  const history = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);
  const [collapsed, setCollapsed] = useState(false);
  const to = (item) => {
    console.log(item);
    if (item.menu_url && item.open_mode === "CURRENT_PAGE") {
      history(item.menu_url);
    } else if (item.menu_url && item.open_mode === "NEW_PAGE") {
      window.open(item.menu_url);
    }
  };

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const initMenu = (list, isChildren) => {
    list = list?.map((item) => {
      if (item?.children?.length > 0) {
        return getItem(
          <a>{item.menu_name}</a>,
          item.menu_url || item.menu_code,
          <ReactSVG
            style={{ width: "16px", height: "16px" }}
            src={item.icon || HomeIcon}
          ></ReactSVG>,
          item?.children?.map((__) =>
            getItem(
              <a onClick={() => to(__)}>{__.menu_name}</a>,
              __.menu_url || __.menu_code
            )
          )
        );
      }
      return getItem(
        <a onClick={() => to(item)}>{item.menu_name}</a>,
        item.menu_url || item.menu_code,
        <ReactSVG
          style={{ width: "16px", height: "16px" }}
          src={item.icon || HomeIcon}
        ></ReactSVG>
      );
    });
    return list;
  };

  return (
    <div className="sidebarContainer">
      <Sider
        trigger={null}
        width={216}
        style={{
          background: "linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)",
          height: "100%",
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        className="site-layout-background"
        collapsible
        collapsed={collapsed}
        collapsedWidth={50}
      >
        <div className="logo">
          {!collapsed && (
            <div className="logoTitle">
              <img
                src={
                  userMenus?.find((_) => _.menu_code === "OPM_PLATFORM")?.icon
                }
                alt=""
              />
              <span>
                {
                  userMenus?.find((_) => _.menu_code === "OPM_PLATFORM")
                    ?.menu_name
                }
              </span>
            </div>
          )}
          <span>
            {collapsed ? (
              <MyIcon
                className="anticon-menu-fold"
                type="icon-zhankai"
                onClick={() => setCollapsed(!collapsed)}
              ></MyIcon>
            ) : (
              <MyIcon
                className="anticon-menu-unfold "
                type="icon-shouqi1"
                onClick={() => setCollapsed(!collapsed)}
              ></MyIcon>
            )}
          </span>
        </div>
        <Menu
          mode="inline"
          // style={{ height: "100%", borderRight: 0 }}
          selectedKeys={[pathname]}
          items={initMenu(props.routerList?.filter((item) => !item.hide))}
        ></Menu>
      </Sider>
    </div>
  );
}
