import Cookies from 'js-cookie'
import psl from "psl";

const Token = 'Token';
const RedirectUrlKey = 'opmRedirectUrl';

const getTokenKey = () => {
  const prefix = localStorage.getItem('prefix')
  return prefix ? prefix + '_' + Token : Token
}

let domain = psl.get(window.location.hostname);
domain = domain && `.${domain}`;

export function getToken() {
  return Cookies.get(getTokenKey());
}

export function setToken(token) {
  let options = {
    expires: new Date(new Date().getTime() + 23 * 60 * 60 * 1000)
  };
  if (domain) {
    options.domain = domain;
  }
  return Cookies.set(getTokenKey(), token, options)
}

export function removeToken() {
  let options = {};
  if (domain) {
    options.domain = domain;
  }
  return Cookies.remove(getTokenKey(), options)
}

export function setRedirectUrl(redirectUrl) {
  return Cookies.set(RedirectUrlKey, redirectUrl)
}

export function getRedirectUrl() {
  return Cookies.get(RedirectUrlKey)
}

export function removeRedirectUrl() {
  return Cookies.remove(RedirectUrlKey)
}