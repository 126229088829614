import {
    createSlice
} from '@reduxjs/toolkit';
const MenuInfo = createSlice({
    name: 'menu',
    initialState: {
        breadcrumbList: [],
    },
    reducers: {
        setBreadcrumbList: (state, action) => {
            state.breadcrumbList = action.payload
        },

    },
});
export const {
    setBreadcrumbList,
} = MenuInfo.actions;

export default MenuInfo;