import { useEffect, useState, useRef } from "react";
import "./index.scss";
import {
  Button,
  Input,
  Table,
  Tag,
  Select,
  message,
  Tooltip,
  Typography,
} from "antd";
import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import EditHtml from "./EditHtml/index";
import apis from "../../apis";
import MessageModal from "../../components/MessageModal";
import { useGetState, useSize } from "ahooks";
import { useDispatch } from "react-redux";
import { setBreadcrumbList } from "../../store/menuInfo/menuInfo";
const { Paragraph, Text } = Typography;

const SubscriptionScheme = (props) => {
  const [selectItem, setSelectItem] = useState({});
  const [handleType, setHandleType] = useState("查看");
  const [editShow, setEditShow] = useState(false);
  const [data, setData] = useState(null);
  const [productName, setProductName] = useState(null);
  const [version, setVersion] = useState(null);
  const [subPlanName, setSubPlanName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem, getDeleteItem] = useGetState(null);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal, getTotal] = useGetState(0);

  const refTable = useRef(null);
  const size = useSize(refTable);
  const dispatch = useDispatch();

  const [productList, setProductList] = useState([]);
  const [versionTypeList, setVersionTypeList] = useState([]);

  const deleteItemFunc = (item) => {
    setDeleteItem(item);
    MessageModal({
      type: "warning",
      description: "确定要删除此条数据吗？",
      // message: "删除项目后将不再更新尽调信息和推送订阅信息",
      title: "删除",
      onCancel: () => {
        setDeleteItem(null);
      },
      onOk: () => {
        console.log("删除", item);
        apis
          .changePlanStatus(item.ver_info?.subs_plan_id, "DELETED")
          .then((res) => {
            if (res.code === 200) {
              getOneDataFunc();
            } else {
              message.error(res.message);
            }
            setDeleteItem(null);
          });
      },
    });
  };

  const handleItem = (type, item) => {
    console.log(type, item);
    apis
      .changePlanStatus(
        item.ver_info?.subs_plan_id,
        type === "失效" ? "INVALID" : "VALID"
      )
      .then((res) => {
        if (res.code === 200) {
          getDataFunc();
        } else {
          message.error(res.message);
        }
      });
  };

  const columns = useRef([
    {
      title: "产品名称",
      dataIndex: "prd_name",
      key: "prd_name",
      align: "center",
      render: (text, item) => (
        <>{item?.subs_plan_info?.product_info?.prd_name}</>
      ),
    },
    {
      title: "版本类型",
      dataIndex: "version_type_name",
      key: "version_type_name",
      align: "center",
      render: (text, item) => <>{item?.subs_plan_info?.version_type_name}</>,
    },
    {
      title: "订阅方案编码",
      dataIndex: "subs_plan_code",
      key: "subs_plan_code",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.subs_plan_info?.subs_plan_code,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.subs_plan_info?.subs_plan_code}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "订阅方案名称",
      key: "subs_plan_name",
      dataIndex: "subs_plan_name",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.ver_info?.subs_plan_name,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.ver_info?.subs_plan_name}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "产品配置模板",
      key: "prd_co_name",
      dataIndex: "prd_co_name",
      align: "center",
      render: (text, item) => <>{item?.subs_plan_info?.prd_co_name}</>,
    },
    {
      title: "订阅方案描述",
      key: "subs_plan_desc",
      dataIndex: "subs_plan_desc",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.ver_info?.subs_plan_desc,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.ver_info?.subs_plan_desc}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "版本",
      key: "cur_ver_no",
      dataIndex: "cur_ver_no",
      align: "center",
      render: (text, item) => <>{item?.ver_info?.ver_no}</>,
    },
    {
      title: "状态",
      key: "is_valid",
      dataIndex: "is_valid",
      align: "center",
      render: (text, item) => (
        <div
          style={{
            color:
              item?.subs_plan_info?.subs_plan_status_code === "失效"
                ? "red"
                : item?.subs_plan_info?.subs_plan_status_code === "删除"
                ? "red"
                : "#535353",
          }}
        >
          {item?.subs_plan_info?.subs_plan_status_code}
        </div>
      ),
    },
    {
      title: "创建人",
      key: "creator",
      dataIndex: "creator",
      align: "center",
      render: (text, item) => <>{item?.ver_info?.user_name}</>,
    },
    {
      title: "创建时间",
      key: "created_time",
      dataIndex: "created_time",
      align: "center",
      render: (text, item) => <>{item?.ver_info?.created_time}</>,
    },
    {
      title: "更新时间",
      key: "updated_time",
      dataIndex: "updated_time",
      align: "center",
      render: (text, item) => <>{item?.ver_info?.updated_time}</>,
    },
    {
      title: "操作",
      key: "action",
      width: 120,
      render: (_, record) => (
        <div className="tableTagsBox">
          <Tooltip title="查看">
            <svg
              t="1704265207410"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="4261"
              width="12"
              height="12"
              onClick={() => {
                setHandleType("查看");
                setSelectItem(record);
                setEditShow(true);
              }}
            >
              <path
                d="M896 64H128c-35.296 0-64 28.704-64 64v768c0 35.296 28.704 64 64 64h592a32 32 0 1 0 0-64H128V128h768v592a32 32 0 1 0 64 0V128c0-35.296-28.704-64-64-64z"
                p-id="4262"
              ></path>
              <path
                d="M791.744 746.496A206.752 206.752 0 0 0 832 624c0-114.688-93.312-208-208-208S416 509.312 416 624s93.312 208 208 208a206.752 206.752 0 0 0 122.496-40.256l110.88 110.88a31.904 31.904 0 0 0 45.248 0 31.968 31.968 0 0 0 0-45.248l-110.88-110.88zM480 624c0-79.392 64.608-144 144-144s144 64.608 144 144-64.608 144-144 144-144-64.608-144-144zM800 264a32 32 0 0 0-32-32H256a32 32 0 0 0 0 64h512a32 32 0 0 0 32-32zM256 422.656a32 32 0 0 0 0 64h96a32 32 0 0 0 0-64H256z"
                p-id="4263"
              ></path>
            </svg>
          </Tooltip>
          {record?.subs_plan_info?.subs_plan_status_code !== "删除" && (
            <Tooltip title="修改">
              <svg
                t="1704265355134"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="5249"
                width="12"
                height="12"
                onClick={() => {
                  setHandleType("修改");
                  setSelectItem(record);
                  setEditShow(true);
                }}
              >
                <path
                  d="M867.388235 173.176471l-102.4-108.42353c-24.094118-30.117647-60.235294-30.117647-84.329411 0l-54.211765 60.235294-457.788235 499.952941c-6.023529 6.023529-6.023529 12.047059-6.02353 18.070589l-36.141176 192.752941c-6.023529 24.094118 12.047059 42.164706 36.141176 36.141176l180.705882-36.141176c6.023529 0 12.047059-6.023529 18.070589-12.047059l427.670588-469.835294 84.329412-90.352941c18.070588-30.117647 18.070588-66.258824-6.02353-90.352941z m-548.141176 608.37647l-126.494118 24.094118 24.094118-132.517647 42.164706-48.188236 102.4 114.447059-42.164706 42.164706z m84.329412-96.37647l-102.4-114.447059 343.341176-373.458824 102.4 108.42353-343.341176 379.482353z m385.505882-415.62353l-108.423529-114.447059 42.164705-48.188235 108.42353 114.447059-42.164706 48.188235zM30.117647 926.117647h963.764706v60.235294h-963.764706z"
                  fill="#222222"
                  p-id="5250"
                ></path>
              </svg>
            </Tooltip>
          )}

          {record?.subs_plan_info?.subs_plan_status_code === "生效" && (
            <Tooltip title="暂停">
              <svg
                onClick={() => {
                  handleItem("失效", record);
                }}
                t="1705891705473"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="2332"
                width="14"
                height="14"
              >
                <path
                  d="M874.058005 149.941995a510.06838 510.06838 0 1 0 109.740156 162.738976 511.396369 511.396369 0 0 0-109.740156-162.738976z m66.278708 362.178731A428.336713 428.336713 0 1 1 512 83.663287a428.698892 428.698892 0 0 1 428.336713 428.336713z"
                  fill="#4D4D4D"
                  p-id="2333"
                ></path>
                <path
                  d="M417.954256 281.533601a41.046923 41.046923 0 0 0-41.77128 40.201839v385.116718a41.892007 41.892007 0 0 0 83.663287 0v-385.116718a41.167649 41.167649 0 0 0-41.892007-40.201839zM606.045744 281.533601a41.046923 41.046923 0 0 0-41.77128 40.201839v385.116718a41.892007 41.892007 0 0 0 83.663287 0v-385.116718a41.167649 41.167649 0 0 0-41.892007-40.201839z"
                  fill="#4D4D4D"
                  p-id="2334"
                ></path>
              </svg>
            </Tooltip>
          )}
          {record?.subs_plan_info?.subs_plan_status_code === "失效" && (
            <Tooltip title="启用">
              <svg
                onClick={() => {
                  handleItem("有效", record);
                }}
                t="1705891879661"
                viewBox="0 0 1085 1024"
                version="1.1"
                p-id="4152"
                width="14"
                height="14"
              >
                <path
                  d="M546.904228 18.10573c-277.76918 0-502.94529 225.17611-502.94529 502.94529 0 277.77164 225.17488 502.94775 502.94529 502.94775 277.76795 0 502.94529-225.17611 502.94529-502.94775C1049.849518 243.28307 824.672178 18.10573 546.904228 18.10573L546.904228 18.10573zM546.904228 973.701659c-249.992631 0-452.651868-202.659237-452.651868-452.651868 0-249.990172 202.659237-452.649408 452.651868-452.649408s452.650638 202.659237 452.650638 452.649408C999.554866 771.042422 796.896859 973.701659 546.904228 973.701659L546.904228 973.701659zM775.203924 485.406478c-78.267962-53.25591-220.1919-132.967608-301.004232-182.358388-33.132146-20.893592-60.993549-12.502952-63.667043 24.399633-1.938099 98.840588 0 289.550264 0 390.101445 1.681079 37.462125 34.709926 42.403293 63.37559 27.362121 80.72133-48.906255 219.456505-132.95777 300.588574-182.112436C773.968017 562.476656 836.097862 528.305364 775.203924 485.406478L775.203924 485.406478z"
                  fill="#272636"
                  p-id="4153"
                ></path>
              </svg>
            </Tooltip>
          )}
          {record?.subs_plan_info?.subs_plan_status_code === "失效" && (
            <Tooltip title="删除">
              <svg
                onClick={() => {
                  deleteItemFunc(record);
                }}
                t="1705891931298"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="5147"
                width="14"
                height="14"
              >
                <path
                  d="M607.897867 768.043004c-17.717453 0-31.994625-14.277171-31.994625-31.994625L575.903242 383.935495c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 351.94087C639.892491 753.593818 625.61532 768.043004 607.897867 768.043004z"
                  fill="#575B66"
                  p-id="5148"
                ></path>
                <path
                  d="M415.930119 768.043004c-17.717453 0-31.994625-14.277171-31.994625-31.994625L383.935495 383.935495c0-17.717453 14.277171-31.994625 31.994625-31.994625 17.717453 0 31.994625 14.277171 31.994625 31.994625l0 351.94087C447.924744 753.593818 433.647573 768.043004 415.930119 768.043004z"
                  fill="#575B66"
                  p-id="5149"
                ></path>
                <path
                  d="M928.016126 223.962372l-159.973123 0L768.043004 159.973123c0-52.980346-42.659499-95.983874-95.295817-95.983874L351.94087 63.989249c-52.980346 0-95.983874 43.003528-95.983874 95.983874l0 63.989249-159.973123 0c-17.717453 0-31.994625 14.277171-31.994625 31.994625s14.277171 31.994625 31.994625 31.994625l832.032253 0c17.717453 0 31.994625-14.277171 31.994625-31.994625S945.73358 223.962372 928.016126 223.962372zM319.946246 159.973123c0-17.545439 14.449185-31.994625 31.994625-31.994625l320.806316 0c17.545439 0 31.306568 14.105157 31.306568 31.994625l0 63.989249L319.946246 223.962372 319.946246 159.973123 319.946246 159.973123z"
                  fill="#575B66"
                  p-id="5150"
                ></path>
                <path
                  d="M736.048379 960.010751 288.123635 960.010751c-52.980346 0-95.983874-43.003528-95.983874-95.983874L192.139761 383.591466c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 480.435411c0 17.717453 14.449185 31.994625 31.994625 31.994625l448.096758 0c17.717453 0 31.994625-14.277171 31.994625-31.994625L768.215018 384.795565c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 479.231312C832.032253 916.835209 789.028725 960.010751 736.048379 960.010751z"
                  fill="#575B66"
                  p-id="5151"
                ></path>
              </svg>
            </Tooltip>
          )}
        </div>
      ),
    },
  ]);

  const getDataFunc = () => {
    setLoading(true);
    const params = {
      product_name: productName,
      version_type_code: version,
      sub_plan_name: subPlanName,
      page: getPageNumber(),
      page_size: getPageSize(),
    };
    apis.getPlanList(params).then((res) => {
      console.log("🚀 ~ 订阅方案列表:", res);
      if (res.code === 200) {
        setData(res.data?.result || []);
        setTotal(res.data?.total_count || 0)
      } else {
        setData([]);
        setTotal(0)
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  const getOneDataFunc = () => {
    setPageNumber(1);
    setPageSize(10);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  };
  //获取产品列表
  const getProductList = () => {
    apis.getProductList().then((res) => {
      console.log("🚀 ~ 产品列表:", res);
      if (res.code === 200) {
        setProductList(res.data);
      }
    });
  };

  //获取版本类型
  const getVersionTypeList = () => {
    apis.getVerType().then((res) => {
      if (res.code === 200) {
        const list =
          Object.keys(res.data || {})?.map((key) => {
            let item = {
              value: (res.data[key] || {})?.dic_item_code,
              label: (res.data[key] || {})?.dic_item_name,
            };
            return item;
          }) || [];
        setVersionTypeList(list);
      }
    });
  };

  useEffect(() => {
    getDataFunc();
    getVersionTypeList();
    getProductList();
  }, []);

  useEffect(() => {
    if (!editShow) {
      dispatch(
        setBreadcrumbList([
          {
            title: "订阅方案",
          },
        ])
      );
    }
  }, [editShow]);

  if (editShow) {
    return (
      <EditHtml
        type={handleType}
        selectItem={selectItem}
        setEditShow={setEditShow}
        getDataFunc={getOneDataFunc}
      ></EditHtml>
    );
  }

  return (
    <div className="subscriptionSchemeContent" ref={refTable}>
      <div className="filterItemsBox">
        <div className="filterItemsContent">
          <div className="filterItem">
            <span>产品名称</span>
            <Select
              style={{
                width: "210px",
              }}
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setProductName(value);
              }}
              value={productName}
              options={productList?.map((_) => ({
                value: _.prd_name,
                label: _.prd_name,
              }))}
              placeholder="请选择产品名称查询"
            />
          </div>
          <div className="filterItem">
            <span>版本类型</span>
            <Select
              style={{
                width: "210px",
              }}
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setVersion(value);
              }}
              value={version}
              options={versionTypeList}
              placeholder="请选择版本类型查询"
            />
          </div>
          <div className="filterItem">
            <span>订阅方案名称</span>
            <Input
              allowClear
              style={{ width: "210px" }}
              value={subPlanName}
              onChange={(e) => {
                setSubPlanName(e.target.value);
              }}
              placeholder="请输入订阅方案名称查询"
            ></Input>
          </div>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => {
              if (loading) {
                return;
              }
              getOneDataFunc();
            }}
          >
            查询
          </Button>
        </div>
        <Button
          type="primary"
          icon={<PlusCircleFilled />}
          onClick={() => {
            setHandleType("新增");
            setSelectItem({});
            setEditShow(true);
          }}
        >
          新增
        </Button>
      </div>
      <div className="tableContent">
        <Table
          loading={loading}
          columns={columns.current}
          dataSource={data}
          className="myFormTable"
          bordered={true}
          rowClassName={(item) => {
            let className = "";
            if (
              item.ver_info.subs_plan_ver_id ===
              ((getDeleteItem() || {})?.ver_info || {})?.subs_plan_ver_id
            ) {
              className = "selectItem";
            }
            return className;
          }}
          scroll={{
            y: size?.height - 220 + "px",
          }}
          pagination={{
            showTotal: (total, range) => "共" + total + "条",
            total: getTotal()
          }}
          onChange={(pageInfo)=>{
            setPageNumber(pageInfo?.current)
            setPageSize(pageInfo?.pageSize)
            setTimeout(() => {
              getDataFunc()
            }, 100);
          }}
          rowKey={() => {
            return Math.random();
          }}
        />
      </div>
    </div>
  );
};
export default SubscriptionScheme;
