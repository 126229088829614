import request from './request';

//登录
function loginFunc(ticket, redirect) {
    return request({
        url: '/sys/login/',
        method: 'get',
        params: {
            ticket,
            redirect
        }
    })
}

//获取菜单
function getMenuOfPersonal() {
    return request({
        url: '/sys/get_personal_menu',
        method: 'get',
    })
}

//切换 企业
function switchEnterprise(co_id) {
    return request({
        url: 'sys/comp/switch/',
        method: 'put',
        data: {
            co_id
        }
    })
}

//获取企业列表
function getEnterprise(include_indiv_org) {
    return request({
        url: 'sys/comp/switch/',
        method: 'get',
        params: {
            include_indiv_org
        }
    })
}

//获取更新日志
function getUpdateLogList() {
    return request({
        url: '/sys/log/updates',
        method: 'get',
    })
}

//退出
function logoutFunc(redirect) {
    return request({
        url: '/sys/logout/',
        method: 'post',
        data: {
            redirect
        }
    })
}

//获取用户信息
function getUserInfo() {
    return request({
        url: '/sys/user/info/',
        method: 'get',
    })
}

//获取用户信息
function getMenus(
    co_id
) {
    return request({
        url: '/sys/user/menu/',
        method: 'get',
        params: {
            co_id
        }
    })
}

//获取个人设置菜单
function getPersonalMenus() {
    return request({
        url: '/sys/menu/personal/',
        method: 'get',

    })
}

/* ============================= 通知 ============================= */
//获取全部通知
function getNotices(page, page_size) {
    return request({
        url: `/sys/get_notice_list`,
        method: 'get',
        params: {
            page,
            page_size
        }
    })
}

//获取未读通知
function getUnreadNotices(page, page_size) {
    return request({
        url: `/sys/get_unread_notice`,
        method: 'get',
        params: {
            page,
            page_size
        }
    })
}

//消息修改已读状态接口
function setReadNotices() {
    return request({
        url: `/sys/put_notice_flag?spread=all`,
        method: 'put',
    })
}

//删除系统消息
function deleteNotices() {
    return request({
        url: `/sys/delete_notice?spread=read`,
        method: 'delete',
    })
}

/* ============================= 订阅方案 ============================= */
//获取订阅方案
function getPlanList(params) {
    return request({
        url: `/plan/list`,
        method: 'get',
        params: params
    })
}

//获取订阅方案及其历史
function getHistoryPlanList(subs_plan_id) {
    return request({
        url: `/plan/history`,
        method: 'get',
        params: {
            subs_plan_id
        }
    })
}
//添加订阅方案
function addPlanList(data) {
    return request({
        url: `/plan/add`,
        method: 'post',
        data
    })
}

//修改订阅方案
function updatePlanList(data) {
    return request({
        url: `/plan/update`,
        method: 'post',
        data
    })
}

//获取产品列表
function getProductList() {
    return request({
        url: `/product/list`,
        method: 'get',
    })
}

//获取许可项列表
function getLicenseItemsList(co_id) {
    return request({
        url: `/sys/item/list`,
        method: 'get',
        params: {
            co_id
        }
    })
}

//获取产品模版列表
function getTemplateList(prd_code) {
    return request({
        url: `/sys/template/list`,
        method: 'get',
        params: {
            prd_code
        }
    })
}

//获取产品版本类型
function getVerType(version_type) {
    return request({
        url: `/sys/get_ver_type`,
        method: 'get',
        params: {
            version_type
        }
    })
}

//更新订阅方案状态
function changePlanStatus(subs_plan_id, status_code) {
    return request({
        url: `/plan/change`,
        method: 'put',
        data: {
            subs_plan_id,
            status_code
        }
    })
}


/* ============================= 企业订阅 ============================= */
//获取订阅方案
function getEnterpriseList(params) {
    return request({
        url: `/enterprise/list`,
        method: 'get',
        params: params
    })
}

//获取企业列表
function getCompanyList(params) {
    return request({
        url: `/sys/enterprise/list`,
        method: 'get',
        params
    })
}

//新增企业订阅方案
function addEnterprise(data) {
    return request({
        url: `/enterprise/add`,
        method: 'post',
        data
    })
}

//获取企业历史订阅方案列表
function getHistoryEnterprise(data) {
    return request({
        url: `/enterprise/history`,
        method: 'get',
        params: data
    })
}

//撤销订阅
function unSubscribe(co_subs_id, co_id, subs_plan_id) {
    return request({
        url: `/enterprise/cancel`,
        method: 'post',
        data: {
            co_subs_id,
            co_id,
            subs_plan_id
        }
    })
}

//获取用户列表
function getUserList(user_name) {
    return request({
        url: `/cons/list`,
        method: 'get',
        params: {
            user_name
        }
    })
}

//创建正式企业
function createFormalEnterprise(user_id, company_name) {
    return request({
        url: `/sys/comp/create`,
        method: 'post',
        data: {
            user_id,
            company_name
        }
    })
}

//删除正式企业
function deleteEnterprise(co_id) {
    return request({
        url: `/sys/comp/delete`,
        method: 'delete',
        data: {
            co_id,
        }
    })
}

/* ============================= 企业管理 ============================= */
//获取企业列表
function getCompList(params) {
    return request({
        url: `/sys/comp/list`,
        method: 'get',
        params: params
    })
}

//获取企业角色
function getCoRole() {
    return request({
        url: `/sys/comp/get_co_role/`,
        method: 'get',
    })
}

//获取企业用户列表
function getCompanyUserList(co_id) {
    return request({
        url: `/sys/comp/get_company_user/?co_id=${co_id}`,
        method: 'get',
    })
}

//创建企业
function createCompany(data) {
    return request({
        url: `/sys/comp/create`,
        method: 'post',
        data
    })
}

//更新企业信息
function updateCompany(data) {
    return request({
        url: `/sys/comp/info`,
        method: 'put',
        data
    })
}

//批量添加企业成员
function bindCompanyUsers(data) {
    return request({
        url: `/sys/comp/user/import/`,
        method: 'post',
        data
    })
}

//更新企业状态
function changeCompanyStatus(data) {
    return request({
        url: `/sys/comp/status`,
        method: 'put',
        data
    })
}

/* ============================= 许可 ============================= */
//获取产品许可列表
function getLicenseList(params) {
    return request({
        url: `/license/list`,
        method: 'get',
        params: params
    })
}

//产品许可申请
function addLicense(data) {
    return request({
        url: `/license/add`,
        method: 'post',
        data
    })
}

//下载许可
function downLoadLicenseFile(id) {
    return request({
        url: `/license/download?license_id=${id}`,
        method: 'get',
        responseType: 'arraybuffer',
    })
}

export default {
    getUserInfo,
    loginFunc,
    getMenus,
    logoutFunc,
    getPersonalMenus,
    getNotices,
    getUnreadNotices,
    setReadNotices,
    deleteNotices,
    getPlanList,
    getProductList,
    getTemplateList,
    getLicenseItemsList,
    getEnterpriseList,
    addPlanList,
    updatePlanList,
    changePlanStatus,
    getCompanyList,
    getVerType,
    addEnterprise,
    getHistoryEnterprise,
    getLicenseList,
    addLicense,
    getMenuOfPersonal,
    downLoadLicenseFile,
    getUpdateLogList,
    switchEnterprise,
    getEnterprise,
    unSubscribe,
    getUserList,
    createFormalEnterprise,
    getHistoryPlanList,
    getCompList,
    getCoRole,
    getCompanyUserList,
    createCompany,
    bindCompanyUsers,
    updateCompany,
    changeCompanyStatus,
    deleteEnterprise
}