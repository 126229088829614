/* 
  @FileDescription: 头部公用组件,
  @
  @params:{
    projectCode(必传参数): 产品code String,
    authCallBack: : 当前项目是否授权 验证后的回调 [Function] 返回值 type: 1(没有授权任何产品) 2(没有授权当前产品,但是有有效的授权产品) 3(没有授权当前产品,且没有有效的授权产品),
    logoDom: 自定义logo样式,
    userMenus: 用户的项目列表 [Array],
    userInfo: 用户的基本信息 [Object],
    personalMenus: 用户的个人设置菜单 [Object],
    logoutBack: 退出函数的回调 [Function],
    NoticesTitle: 通知抽屉的标题,默认为 通知提醒 [String],
    noticesIntervalMinute: 通知轮训间隔时间,单位为分钟,默认为5 [Number],
    showNotice: 是否显示通知图标,默认为true [Boolean],
    getEnterpriseFunc: 获取企业列表的函数(axios包装好的函数) [Function],
    switchEnterpriseFunc: 切换企业的函数(axios包装好的函数) [Function],
    switchEnterpriseCallBack: 切换企业成功后的回调 [Function],
    licenseName: 当前产品的订阅方案名称 [String],
    getAuthTimeFunc: 获取授权时长的函数(axios包装好的函数) [Function],
    getAdminsFunc: 获取当前项目的管理员的函数(axios包装好的函数) [Function],
    contactBusinessCallBack: 联系商务的回调 [Function],

    ⬇️ ========>  若showNotice为true,则以下参数必传
    getUnreadNoticesFunc: 获取未读通知的函数(axios包装好的函数) [Function],
    getNoticesFunc: 获取所有通知的函数(axios包装好的函数) [Function],
    setReadNoticesFunc: 修改通知未读变已读的函数(axios包装好的函数) [Function],
    deleteNoticesFunc: 清除所有通知的函数(axios包装好的函数) [Function],
    ⬆️
    getUpdateLogList:  获取更新日志的函数(axios包装好的函数) [Function],
    validationFunc: 验证函数,验证以上函数返回值哪种状态为正确状态,默认为 (res) => res.code === 200 [Function],
  },
*/

import "./index.scss";
import {
  Layout,
  Avatar,
  message,
  Badge,
  Popover,
  Drawer,
  Button,
  Tooltip,
  Typography,
} from "antd";
import logo from "./logo.png";
import { ReactSVG } from "react-svg";
import NoticeDrawer from "./components/NoticeDrawer";
import { useEffect, useRef, useState } from "react";
import { useGetState, useUnmount } from "ahooks";
import UpdateLogsDrawer from "./components/UpdateLogsDrawer";
import {
  UnorderedListOutlined,
  SwapOutlined,
  CloseOutlined,
  GoldFilled,
  CheckOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import psl from "psl";
import Cookie from "js-cookie";
import moment from "moment";
const { Paragraph } = Typography;

const { Header } = Layout;
const UserInfoCard = (props) => {
  const {
    userInfo = {},
    personalMenus = {},
    logoutBack,
    setUpdateLogsVisible,
    enterpriseList = [],
    validationFunc,
    switchEnterpriseFunc,
    switchEnterpriseCallBack,
    licenseName,
    getEnterprise,
    userMenus,
    projectCode = null,
    menuClickCallBack,
  } = props;

  const [visible, setVisible] = useState(false);
  const [coId, setCoId, getCoId] = useGetState(null);
  const openNewWin = (item) => {
    if (item?.menu_name === "退出登录") {
      logoutBack && logoutBack();
      return;
    }
    if (item?.menu_name === "更新日志") {
      setUpdateLogsVisible(true);
      return;
    }
    if (item?.menu_name === "联系客服") {
      let href = item.menu_url;
      const menuItem =
        userMenus?.find((_) => _.menu_code === projectCode) || {};
      let params = {
        project_name: menuItem?.menu_name || "导航页",
        co_name: userInfo?.co_name,
        co_id: userInfo?.co_id,
        user_name: userInfo?.show_name,
        user_id: userInfo?.co_user_id,
      };
      href =
        href + "?info=" + window?.encodeURIComponent(JSON.stringify(params));
      window.open(href, item?.menu_name);
      return;
    }
    if (!item.menu_url) {
      message.info("没有设置跳转链接!");
      return;
    }
    let domain = psl.get(window.location.hostname);
    domain = domain && `.${domain}`;
    let options = {
      expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    };
    if (domain) {
      options.domain = domain;
    }
    const href = window.location;
    Cookie.set("callbackUrl", href, options);
    menuClickCallBack && menuClickCallBack(item);
    if (menuClickCallBack && menuClickCallBack(item)) {
      return;
    }
    window.open(item.menu_url, item?.menu_name);
  };

  //切换企业
  const switchEnterprise = () => {
    if (getCoId() === userInfo?.co_id) {
      setVisible(false);
      return;
    }
    switchEnterpriseFunc &&
      switchEnterpriseFunc(getCoId()).then((res) => {
        console.log(res);
        if (validationFunc(res)) {
          switchEnterpriseCallBack && switchEnterpriseCallBack(res.data);
          setVisible(false);
        } else {
          message.error(res?.data?.message || "切换失败");
        }
      });
  };
  return (
    <div className="userInfoCardContent">
      <div className="userLogoContent">
        <Avatar size={52} src={userInfo?.pf_photo} />
        <div className="userNameBox">
          {/* {userInfo?.show_name} */}
          <Paragraph
            ellipsis={{
              rows: 1,
              tooltip: userInfo?.show_name,
            }}
            style={{
              maxWidth: "180px",
              marginBottom: "0px",
            }}
          >
            {userInfo?.show_name}
          </Paragraph>
          {userInfo?.co_id && userInfo?.co_type_code !== "INDIV_ORG" ? (
            userInfo?.is_admin || userInfo?.is_creator ? (
              <div className="userRoleTag">
                {userInfo?.is_creator ? "创建者" : "管理员"}
              </div>
            ) : (
              <div className="userRoleTag">成员</div>
            )
          ) : null}
        </div>
        {licenseName && (
          <Tooltip
            visible={licenseName?.length > 10 ? undefined : false}
            title={<span style={{ fontSize: "12px" }}>{licenseName}</span>}
          >
            <div className="userTagBox">
              {licenseName?.slice(0, 10) +
                (licenseName?.length > 10 ? "..." : "")}
            </div>
          </Tooltip>
        )}
        {userInfo?.pf_photo && (
          <div className="userLogoBg">
            <img src={userInfo?.pf_photo} alt="" />
          </div>
        )}
        {userInfo?.co_name && (
          <div className="userCoNameBox">
            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip:
                  userInfo?.co_type_code !== "INDIV_ORG"
                    ? userInfo?.co_name
                    : "用户ID: " + userInfo?.individual_id,
              }}
              style={{
                maxWidth: "200px",
                marginBottom: "0px",
                color: "#9E9E9E",
              }}
            >
              {userInfo?.co_type_code !== "INDIV_ORG"
                ? userInfo?.co_name
                : "用户ID: " + userInfo?.individual_id}
            </Paragraph>

            {(enterpriseList || [])?.filter(
              (_) => _.co_type_code !== "INDIV_ORG"
            )?.length > 1 && userInfo?.co_type_code !== "INDIV_ORG" ? (
              // <SwapOutlined
              //   onClick={() => {
              //     setCoId(userInfo.co_id);
              //     getEnterprise && getEnterprise();
              //     setTimeout(() => {
              //       setVisible(true);
              //     }, 100);
              //   }}
              // />
              <svg
                fill="none"
                version="1.1"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                className="anticon"
                onClick={() => {
                  setCoId(userInfo.co_id);
                  getEnterprise && getEnterprise();
                  setTimeout(() => {
                    setVisible(true);
                  }, 100);
                }}
              >
                <g>
                  <path
                    d="M12.0909,0C13.1453,0,14,0.854729,14,1.90909L14,12.0909C14,13.1453,13.1453,14,12.0909,14L1.90909,14C0.854729,14,0,13.1453,0,12.0909L0,1.90909C-1.69927e-7,0.854729,0.854729,0,1.90909,0L12.0909,0ZM10.6069,6.78746L9.72491,6.78746C9.65954,6.78746,9.60655,6.84045,9.60655,6.90582L9.60655,8.27909C9.60655,8.62031,9.33049,8.89722,8.98927,8.89827L5.40909,8.89827L5.40909,7.96918C5.40923,7.92424,5.38364,7.88319,5.34323,7.86353C5.30282,7.84387,5.25473,7.84907,5.21945,7.87691L3.32182,9.36791C3.2607,9.41504,3.2607,9.50723,3.32182,9.55436L5.22009,11.0454C5.29645,11.1065,5.40973,11.0524,5.40973,10.9531L5.40973,10.024L8.98864,10.024C9.94881,10.0222,10.7258,9.24244,10.724,8.28227L10.7253,8.28227L10.7253,6.90582C10.7253,6.84045,10.6723,6.78745,10.6069,6.78746ZM8.77864,2.95655C8.74346,2.92838,8.69525,2.9229,8.65465,2.94246C8.61405,2.96201,8.58827,3.00312,8.58836,3.04818L8.58836,3.97727L5.00946,3.97727C4.04928,3.97938,3.27262,4.75946,3.27473,5.71964L3.27473,7.09227C3.27473,7.15845,3.32818,7.21064,3.39309,7.21064L4.27509,7.21064C4.34046,7.21064,4.39345,7.15764,4.39346,7.09227L4.39346,5.71964C4.39346,5.37855,4.67027,5.09982,5.01073,5.09982L8.59091,5.09982L8.59091,6.02891C8.59091,6.12627,8.70418,6.18164,8.78055,6.12118L10.6788,4.63018C10.7402,4.58449,10.7395,4.49238,10.6775,4.44755L8.77864,2.95655Z"
                    fill="#0068B2"
                    fill-opacity="1"
                  />
                </g>
              </svg>
            ) : null}
          </div>
        )}
      </div>
      <div className="personalMenusContent">
        {Object.keys(personalMenus || {}).map((key, index) => (
          <div className="personalMenusBox" key={key}>
            {index ? <div className="personalMenusLine"></div> : null}
            {(personalMenus[key] || [])?.map((item) => (
              <div
                className="personalMenusItem"
                onClick={() => {
                  openNewWin(item);
                }}
                key={item?.menu_name}
              >
                <ReactSVG
                  className="personalMenusItemIcon"
                  src={item?.icon}
                ></ReactSVG>
                {item.menu_name}
              </div>
            ))}
          </div>
        ))}
      </div>

      <Drawer
        className="enterpriseDrawer"
        width="600px"
        closable={false}
        title="切换企业"
        zIndex={1031}
        placement="right"
        push={false}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              setVisible(false);
            }}
          />
        }
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              onClick={() => {
                // props.onClose();
                switchEnterprise();
              }}
              type="primary"
            >
              确定
            </Button>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              取消
            </Button>
          </div>
        }
      >
        <div className="enterpriseDrawerContent">
          {(enterpriseList || [])
            ?.filter((_) => _.co_type_code !== "INDIV_ORG")
            ?.map((item) => {
              return (
                <div
                  key={item?.co_id}
                  onClick={() => {
                    setCoId(item.co_id);
                  }}
                  className={
                    item?.co_id === coId
                      ? "enterpriseItem activeEnterpriseItem"
                      : "enterpriseItem"
                  }
                >
                  <GoldFilled />
                  <span className="labelSpan">
                    {item?.co_name}
                    <CheckOutlined />
                  </span>
                </div>
              );
            })}
        </div>
      </Drawer>
    </div>
  );
};
const MoreProjectPopoverContent = (props) => {
  const { userMenus } = props;

  return (
    <div className="moreProjectPopoverContent">
      {userMenus?.map((item) => (
        <div
          className="projectItemBox"
          key={item.menu_name}
          onClick={() => {
            window.open(item.menu_url, item?.menu_name);
          }}
        >
          <img src={item.icon} alt={item.menu_name} />
          <span>{item.menu_name}</span>
        </div>
      ))}
    </div>
  );
};

export function MyHeader(props) {
  const {
    projectCode = null,
    authCallBack,
    logoDom = null,
    userInfo = {},
    userMenus = null,
    personalMenus = {},
    logoutBack,
    licenseName,
    showNotice = true,
    getUnreadNoticesFunc,
    getNoticesFunc,
    setReadNoticesFunc,
    deleteNoticesFunc,
    getUpdateLogList,
    getEnterpriseFunc,
    switchEnterpriseFunc,
    switchEnterpriseCallBack,
    validationFunc = (res) => res.code === 200,
    NoticesTitle = "通知提醒",
    noticesIntervalMinute = 5,
    getAuthTimeFunc = null,
    getAdminsFunc = null,
    contactBusinessCallBack = null,
    menuClickCallBack,
  } = props;

  const [noticeVisible, setNoticeVisible] = useState(false);
  const [updateLogsVisible, setUpdateLogsVisible] = useState(false);
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [unreadNoticesTotal, setUnreadNoticesTotal] = useState(0);
  const [showUserMenus, setShowUserMenus] = useState([]);
  const [authTimeArr, setAuthTimeArr] = useState([]);
  const [admins, setAdmins] = useState([]);

  // console.log(enterpriseList);
  const timer = useRef(null);

  useUnmount(() => {
    clearInterval(timer.current);
  });

  useEffect(() => {
    if (!userMenus) {
      return;
    }
    //授权项目列表为空
    if (userMenus?.length === 0) {
      authCallBack && authCallBack(1);
    } else {
      //授权项目列表不为空 并且没有授权此项目
      if (!userMenus?.some((item) => item.menu_code === projectCode)) {
        //跳转第一个授权的项目
        const findItem = userMenus?.find((i) => i.menu_url);
        if (findItem) {
          window.location.href = findItem.menu_url;
          authCallBack && authCallBack(2);
        } else {
          authCallBack && authCallBack(3);
        }
      }
    }
    const _userMenus = (userMenus || [])?.filter((_) => _.menu_url);
    setShowUserMenus(JSON.parse(JSON.stringify(_userMenus || [])));
  }, [userMenus]);

  const getNotices = () => {
    getUnreadNoticesFunc &&
      getUnreadNoticesFunc().then((res) => {
        console.log("获取未读通知", new Date(), res);
        if (validationFunc(res)) {
          setUnreadNoticesTotal(res.data?.total);
        }
      });
  };

  //获取企业列表
  const getEnterprise = () => {
    if (getEnterpriseFunc) {
      getEnterpriseFunc().then((res) => {
        console.log("-----", res);
        if (validationFunc(res)) {
          setEnterpriseList(res.data);
        } else {
          setEnterpriseList([]);
        }
      });
    }
  };

  //切换企业类型
  const switchCoType = (isEnterprise) => {
    if (getEnterpriseFunc) {
      getEnterpriseFunc().then((res) => {
        console.log("-----", res);
        if (validationFunc(res)) {
          // setEnterpriseList(res.data);
          console.log(111);
          let item = (res.data || [])?.find((_) => {
            if (isEnterprise) {
              return (
                _.co_type_code === "FORMAL_CORP" ||
                _.co_type_code === "ADMIN_GROUP"
              );
            } else {
              return _.co_type_code === "INDIV_ORG";
            }
          });
          if (item) {
            switchEnterpriseFunc &&
              switchEnterpriseFunc(item.co_id).then((res) => {
                console.log(res);
                if (validationFunc(res)) {
                  switchEnterpriseCallBack &&
                    switchEnterpriseCallBack(res.data);
                  // setVisible(false);
                } else {
                  message.error(res?.data?.message || "切换失败");
                }
              });
          }
        } else {
          // setEnterpriseList([]);
          message.error(res.data);
        }
      });
    }
  };

  const authTimeFunc = () => {
    if (!(userInfo?.is_admin || userInfo?.is_creator)) {
      getAdminsFunc &&
        getAdminsFunc().then((res) => {
          if (validationFunc(res)) {
            setAdmins(res.data);
          } else {
            setAdmins([]);
          }
        });
    }
    getAuthTimeFunc().then((res) => {
      if (validationFunc(res)) {
        setAuthTimeArr(res.data);
      } else {
        setAuthTimeArr([]);
      }
    });
  };

  useEffect(() => {
    if (getAuthTimeFunc) {
      authTimeFunc();
    }
    if (showNotice) {
      getNotices();
      timer.current = setInterval(() => {
        getNotices();
      }, noticesIntervalMinute * 60 * 1000);
    }
    getEnterprise();
  }, []);

  const initAuthDom = () => {
    if (JSON.stringify(authTimeArr) === "[]") {
      return;
    }
    let color = "";
    let text = "";
    if (authTimeArr?.at(0) === false) {
      color = "#FE545F";
      text = "您的订阅已经过期，";
    } else if (
      moment(new Date())
        .add(authTimeArr?.at(2) || 0, "days")
        .isAfter(authTimeArr?.at(1))
    ) {
      color = "#61D4AD";
      text = `您的订阅即将过期（截止时间：${authTimeArr?.at(1)}），`;
    }
    return (
      <div className="authTimeBox" style={{ color }}>
        {text}
        {text &&
          (userInfo?.is_admin || userInfo?.is_creator ? (
            <>
              请点击
              <a
                onClick={() => {
                  contactBusinessCallBack && contactBusinessCallBack();
                }}
              >
                联系商务
              </a>
              续订！
            </>
          ) : (
            <>
              请联系
              <Popover
                placement="bottomLeft"
                title={null}
                // visible={true}
                overlayClassName="headerAdminPopover"
                content={
                  <div className="adminPopoverContent">
                    {(admins || [])?.map((item) => (
                      <div className="adminPopoverItem">
                        <span>
                          <Avatar
                            style={{ marginRight: "8px" }}
                            size={24}
                            src={item?.pf_photo}
                          />
                          {item?.show_name}
                        </span>
                        <span>{item?.assist_name}</span>
                      </div>
                    ))}
                  </div>
                }
              >
                <a className="adminBox">管理员</a>
              </Popover>
              续订！
            </>
          ))}
      </div>
    );
  };

  return (
    <div className="headerContainer">
      <Header>
        <div className="left-title-box">
          {showUserMenus?.length > 1 ? (
            <Popover
              placement="bottomLeft"
              title={null}
              // open={true}
              overlayClassName="moreProjectPopover"
              content={
                <MoreProjectPopoverContent
                  userMenus={showUserMenus}
                ></MoreProjectPopoverContent>
              }
            >
              <div className="moreProjectBox">
                <UnorderedListOutlined />
              </div>
            </Popover>
          ) : null}
          <span className="left-title">
            {logoDom ? logoDom : <img src={logo} alt="" className="logoImg" />}
          </span>
          {(userInfo?.co_type_code === "FORMAL_CORP" ||
            userInfo?.co_type_code === "INDIV_ORG") && (
            <div className="typeBox">
              {userInfo?.co_type_code === "INDIV_ORG" ? "个人版" : "企业版"}
            </div>
          )}
          {initAuthDom()}
        </div>
        <div className="ant-header-right">
          {userInfo?.co_type_code !== "INDIV_ORG" &&
            enterpriseList?.some((_) => _.co_type_code === "INDIV_ORG") && (
              <div className="typeBtnBox">
                <div
                  className="typeBtn"
                  onClick={() => {
                    switchCoType(false);
                  }}
                >
                  <CheckCircleOutlined />
                  切换个人版
                </div>
              </div>
            )}
          {userInfo?.co_type_code !== "FORMAL_CORP" &&
            userInfo?.co_type_code !== "ADMIN_GROUP" &&
            enterpriseList?.some(
              (_) =>
                _.co_type_code === "FORMAL_CORP" ||
                _.co_type_code === "ADMIN_GROUP"
            ) && (
              <div className="typeBtnBox" style={{ marginLeft: "10px" }}>
                <div
                  className="typeBtn"
                  onClick={() => {
                    switchCoType(true);
                  }}
                >
                  <CheckCircleOutlined />
                  切换企业版
                </div>
              </div>
            )}
          {/* {(userInfo?.co_type_code === "FORMAL_CORP" ||
            userInfo?.co_type_code === "INDIV_ORG") && (
            <div className="typeBtnBox">
              <div className="typeBtn" onClick={switchCoType}>
                <CheckCircleOutlined />
                切换
                {userInfo?.co_type_code === "INDIV_ORG" ? "企业版" : "个人版"}
              </div>
            </div>
          )} */}
          <div className="menuItem">
            {showNotice && (
              <div onClick={() => setNoticeVisible(true)}>
                <Badge dot={unreadNoticesTotal > 0}>
                  <svg
                    t="1700034494573"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    p-id="4887"
                    width="18"
                    height="18"
                    className="cursor-pointer"
                  >
                    <path
                      d="M512 64c16.569 0 30 13.431 30 30l0.003 36.008C681.395 137.891 792 253.424 792 394.79v201.955a30 30 0 0 0 14.1 25.44l48.92 30.575C895.443 678.025 920 722.331 920 770c0 49.706-40.294 90-90 90H638.521C624.978 917.335 573.471 960 512 960c-61.471 0-112.978-42.666-126.521-100H194c-49.706 0-90-40.294-90-90 0-47.669 24.557-91.975 64.98-117.24l48.92-30.575a30 30 0 0 0 14.1-25.44V394.79c0-141.366 110.605-256.9 249.998-264.782L482 94c0-16.569 13.431-30 30-30z m63.264 796H448.736c11.235 23.65 35.34 40 63.264 40s52.03-16.35 63.264-40zM526.79 189.58h-29.58C383.876 189.58 292 281.454 292 394.79v201.955a90 90 0 0 1-42.3 76.32l-48.92 30.575A78.255 78.255 0 0 0 164 770c0 16.569 13.431 30 30 30h636c16.569 0 30-13.431 30-30 0-26.981-13.9-52.06-36.78-66.36l-48.92-30.575a90 90 0 0 1-42.3-76.32V394.79c0-113.335-91.876-205.21-205.21-205.21z"
                      fill="#000000"
                      p-id="4888"
                    ></path>
                  </svg>
                </Badge>
              </div>
            )}
          </div>

          {/* <div className="menuItem"></div> */}

          <div className="menuItem">
            <Popover
              placement="bottomRight"
              title={null}
              overlayClassName="userInfoCardPopover"
              content={
                <UserInfoCard
                  userInfo={userInfo}
                  personalMenus={personalMenus}
                  logoutBack={logoutBack}
                  setUpdateLogsVisible={setUpdateLogsVisible}
                  switchEnterpriseFunc={switchEnterpriseFunc}
                  enterpriseList={enterpriseList}
                  switchEnterpriseCallBack={switchEnterpriseCallBack}
                  validationFunc={validationFunc}
                  licenseName={licenseName}
                  getEnterprise={getEnterprise}
                  projectCode={projectCode}
                  userMenus={userMenus}
                  menuClickCallBack={menuClickCallBack}
                ></UserInfoCard>
              }
            >
              {/* {userInfo?.show_name} */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    tooltip: userInfo?.show_name,
                  }}
                  style={{
                    maxWidth: "160px",
                    marginBottom: "0px",
                    display: "inline-block",
                  }}
                >
                  {userInfo?.show_name}
                </Paragraph>
                <Avatar
                  style={{ marginLeft: "8px" }}
                  size={28}
                  src={userInfo?.pf_photo}
                />
              </div>
            </Popover>
          </div>
        </div>
      </Header>

      <NoticeDrawer
        visible={noticeVisible}
        onClose={() => setNoticeVisible(false)}
        getUnreadNoticesFunc={getUnreadNoticesFunc}
        getNoticesFunc={getNoticesFunc}
        setReadNoticesFunc={setReadNoticesFunc}
        deleteNoticesFunc={deleteNoticesFunc}
        setUnreadNoticesTotal={setUnreadNoticesTotal}
        validationFunc={validationFunc}
        NoticesTitle={NoticesTitle}
      ></NoticeDrawer>

      <UpdateLogsDrawer
        visible={updateLogsVisible}
        onClose={() => setUpdateLogsVisible(false)}
        validationFunc={validationFunc}
        getUpdateLogList={getUpdateLogList}
      ></UpdateLogsDrawer>
    </div>
  );
}

export default MyHeader;
