import { useEffect, useRef, useState } from "react";
import "./index.scss";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import {
  Input,
  Button,
  Select,
  message,
  TreeSelect,
  Tooltip,
  DatePicker,
  Typography,
  InputNumber,
} from "antd";
import { licenseColumns } from "../../../LicenseColumnsConfig";
import apis from "../../../apis";
import { useGetState } from "ahooks";
import MessageModal from "../../../components/MessageModal";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setBreadcrumbList } from "../../../store/menuInfo/menuInfo";
const { Paragraph, Text } = Typography;

const EditHtml = (props) => {
  const { type, selectItem = {}, setEditShow, getDataFunc } = props;

  const [defaultInfo, setDefaultInfo] = useState([]);

  const [info, setInfo, getInfo] = useGetState([]);
  const [deleteList, setDeleteList, getDeleteList] = useGetState([]);
  const [productList, setProductList] = useState([]);
  const [productId, setProductId, getProductId] = useGetState(null);

  const [versionTypeList, setVersionTypeList] = useState([]);
  const [versionType, setVersionType, getVersionType] = useGetState(null);

  const [templateList, setTemplateList] = useState([]);
  const [templateId, setTemplateId] = useState(null);

  const [planId, setPlanId, getPlanId] = useGetState(null);
  const [planList, setPlanList, getPlanList] = useGetState([]);

  const [planName, setPlanName] = useState(null);
  const [planDesc, setPlanDesc] = useState(null);
  const [planCode, setPlanCode] = useState(null);

  const [companyCode, setCompanyCode] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [esn, setEsn] = useState(null);
  const [describe, setDescribe] = useState(null);

  const [licenseItemsList, setLicenseItemsList] = useState([]);
  const [checkStatus, setCheckStatus, getCheckStatus] = useGetState(false);
  const dispatch = useDispatch();

  //获取产品列表
  const getProductList = () => {
    apis.getProductList().then((res) => {
      console.log("🚀 ~ 产品列表:", res);
      if (res.code === 200) {
        setProductList(res.data);
      }
    });
  };

  //获取版本类型
  const getVersionTypeList = () => {
    apis.getVerType().then((res) => {
      if (res.code === 200) {
        const list =
          Object.keys(res.data || {})?.map((key) => {
            let item = {
              value: (res.data[key] || {})?.dic_item_code,
              label: (res.data[key] || {})?.dic_item_name,
            };
            return item;
          }) || [];
        setVersionTypeList(list);
      }
    });
  };

  //获取订阅方案
  const getPlanListFunc = () => {
    const params = {
      product_name: getProductId(),
      version_type_code: getVersionType(),
      subs_plan_status_code: "VALID",
      page: 1,
      page_size: 9999,
    };
    apis.getPlanList(params).then((res) => {
      console.log("🚀 ~ 订阅方案:", res);
      if (res.code === 200) {
        setPlanList(res.data?.result || []);
      }
    });
  };

  //初始化许可项
  const initLicenseItems = (data, defaultInfo) => {
    const planItem = getPlanList()?.find(
      (_) =>
        _?.ver_info?.subs_plan_id + "~~~" + _?.ver_info?.ver_no === getPlanId()
    );
    let list = [];
    if (defaultInfo) {
      defaultInfo?.forEach((item) => {
        const dicItem = data?.find(
          (_) =>
            _.dic_item_code === item.license_item_type_code ||
            _.dic_item_code === item.subs_plan_item_type_code
        );
        if (dicItem) {
          if (dicItem.dic_item_name === "授权截止日期") {
            item.type = "date";
            if (planItem?.ver_info?.subs_default_dur) {
              let interval = "d";
              if (planItem?.ver_info?.subs_dur_unit === "年") {
                interval = "y";
              } else if (planItem?.ver_info?.subs_dur_unit === "月") {
                interval = "M";
              }
              item.item_auth_value = dayjs(new Date())
                .add(planItem?.ver_info?.subs_default_dur, interval)
                .format("YYYY-MM-DD");
            }
          }
          if (item.license_item_type_code) {
            item.subs_plan_item_type_code = item.license_item_type_code;
          }
          if (item.license_item_code) {
            item.subs_plan_item_code = item.license_item_code;
          }
          if (item.license_item_name) {
            item.subs_plan_item_name = item.license_item_name;
          }
          if (item.license_item_desc) {
            item.subs_plan_item_desc = item.license_item_desc;
          }
          item = { ...item, ...dicItem, seq: item.seq };
          list.push(item);
        }
      });
    }
    console.log(list);
    setInfo(list);
  };

  //获取许可项列表
  const getLicenseItemsList = (co_id, defaultInfo) => {
    apis.getLicenseItemsList(co_id).then((res) => {
      console.log("🚀 ~ 许可项列表:", res);
      if (res.code === 200) {
        setLicenseItemsList(res.data);
        initLicenseItems(res.data, defaultInfo);
      }
    });
  };

  useEffect(() => {
    if (type === "申请") {
      getProductList();
      getVersionTypeList();
      // getPlanList();
      setInfo([]);
    }

    if (type === "查看") {
      getLicenseItemsList(
        (selectItem?.subs_plan || {})?.prd_co_id,
        selectItem?.license_item || []
      );
    }
    setDefaultInfo(JSON.parse(JSON.stringify(selectItem || {})));
    dispatch(
      setBreadcrumbList([
        {
          title: (
            <span
              className="backSpan"
              onClick={() => {
                setEditShow(false);
              }}
            >
              产品许可
            </span>
          ),
        },
        {
          title: type + "软件许可",
        },
      ])
    );
  }, [type]);

  const initItem = (item, column, index) => {
    // console.log(item, column, index);
    if (type === "查看") {
      //授权值 且为多选框时
      if (item.type === "more_select" && column.key === "item_auth_value") {
        //code和对应的中文拿出来
        const codeObj = {};
        let count = 1;
        const flatTree = (list, codeKey, nameKey) => {
          list?.forEach((_) => {
            if (_?.children) {
              flatTree(_?.children, codeKey, nameKey);
            }
            codeObj[_[codeKey]] = { name: _[nameKey], index: count };
            count = count + 1;
          });
        };
        flatTree(item.fun_tree?.children || [], "func_code", "func_name");
        flatTree(item.menu_tree?.children || [], "menu_code", "menu_name");
        const funList = (
          (item?.item_auth_value || {})[item.fun_key] || []
        )?.sort((a, b) => codeObj[a]?.index - codeObj[b]?.index);
        const menuList = (
          (item?.item_auth_value || {})[item.menu_key] || []
        )?.sort((a, b) => codeObj[a]?.index - codeObj[b]?.index);
        //最多显示几个内容
        const max = 1;
        return (
          <div className="moreSelectDiv">
            <div className="menuDiv">
              <div className="typeDiv">{item.menu_key}</div>
              {menuList?.slice(0, max)?.map((_) => (
                <span>{codeObj[_]?.name}</span>
              ))}
              {menuList?.length > max && (
                <Tooltip
                  overlayClassName="moreSelectTooltip"
                  placement="bottom"
                  title={
                    <div className="moreSelectTooltipDiv">
                      {menuList?.slice(max)?.map((_) => (
                        <span>{codeObj[_]?.name}</span>
                      ))}
                    </div>
                  }
                  color="#fff"
                >
                  <div className="countDiv">{menuList?.length - max}</div>
                </Tooltip>
              )}
            </div>
            <div className="funcDiv">
              <div className="typeDiv">{item.fun_key}</div>
              {funList?.slice(0, max)?.map((_, _index) => (
                <span key={_index}>{codeObj[_]?.name}</span>
              ))}
              {funList?.length > max && (
                <Tooltip
                  overlayClassName="moreSelectTooltip"
                  placement="bottom"
                  title={
                    <div className="moreSelectTooltipDiv">
                      {funList?.slice(max)?.map((_, _index) => (
                        <span key={_index}>{codeObj[_]?.name}</span>
                      ))}
                    </div>
                  }
                  color="#fff"
                >
                  <div className="countDiv">{funList?.length - max}</div>
                </Tooltip>
              )}
            </div>
          </div>
        );
      }
      //许可项类型 显示中文
      if (column.key === "subs_plan_item_type_code") {
        return (
          <div>
            <Paragraph
              style={{ marginBottom: "0px" }}
              ellipsis={{
                rows: 2,
                tooltip: {
                  title: item?.dic_item_name || "-",
                },
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                }}
              >
                {item?.dic_item_name || "-"}
              </div>
            </Paragraph>
          </div>
        );
      }
      return (
        <div>
          <Paragraph
            style={{ marginBottom: "0px" }}
            ellipsis={{
              rows: 2,
              tooltip: {
                title: item[column.key] || "-",
              },
            }}
          >
            <div
              style={{
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {item[column.key] ?? "-"}
            </div>
          </Paragraph>
        </div>
      );
    }
    //许可项类型 显示中文
    if (column.key === "subs_plan_item_type_code") {
      return (
        <div>
          <Paragraph
            style={{ marginBottom: "0px" }}
            ellipsis={{
              rows: 2,
              tooltip: {
                title: item?.dic_item_name || "-",
              },
            }}
          >
            <div
              style={{
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {item?.dic_item_name || "-"}
            </div>
          </Paragraph>
        </div>
      );
    }
    if (
      column.key === "subs_plan_item_code" ||
      column.key === "subs_plan_item_name"
    ) {
      return (
        <div>
          <Paragraph
            style={{ marginBottom: "0px" }}
            ellipsis={{
              rows: 2,
              tooltip: {
                title: item[column.key] || "-",
              },
            }}
          >
            <div
              style={{
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {item[column.key] || "-"}
            </div>
          </Paragraph>
        </div>
      );
    }
    //
    let disabled = false;
    let placeholder = `请输入${column?.name}`;
    if (column.key === "item_alert_value" && item.alert_value) {
      disabled = true;
      placeholder = "不涉及";
    }
    if (column.key === "item_auth_value" && item.type === "select") {
      return (
        <Select
          style={{ width: "100%" }}
          disabled={item.require}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => {
            setInfo((prev) => {
              const licenseItem =
                licenseItemsList?.find((__) => __.dic_item_code === value) ||
                {};
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  _[column.key] = value;
                  _ = { ...(_ || {}), ...licenseItem };
                }
                return _;
              });
              return [...prev];
            });
          }}
          status={
            column.require && getCheckStatus() && !item[column.key]
              ? "error"
              : ""
          }
          value={item[column.key]}
          options={(item?.select_content || [])?.map((_) => ({
            value: _,
            label: _,
          }))}
          placeholder={`请选择${column?.name}`}
        ></Select>
      );
    }
    if (
      column.key === "item_auth_value" &&
      item.dic_item_name === "授权截止日期"
    ) {
      const disabledDate = (current) => {
        return current && current < dayjs(new Date()).endOf("day");
      };
      return (
        <DatePicker
          style={{ width: "100%" }}
          format="YYYY-MM-DD"
          disabledDate={disabledDate}
          value={item[column.key] ? dayjs(item[column.key]) : null}
          status={
            column.require && getCheckStatus() && !item[column.key]
              ? "error"
              : ""
          }
          onChange={(value, dateString) => {
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  _[column.key] = dateString;
                }
                return _;
              });
              return [...prev];
            });
          }}
        />
      );
    }
    if (column.key === "item_auth_value" && item.type === "more_select") {
      const initTreeData = (list, name_key, code_key) => {
        return list?.map((_) => {
          const __ = {
            title: _[name_key],
            value: _[code_key],
            key: _[code_key],
          };
          if (_.children) {
            __.children = initTreeData(_.children, name_key, code_key);
          }
          return __;
        });
      };

      const tagRender = (props) => {
        return <div className="moreSelectTag">{props?.label}</div>;
      };
      const maxTagPlaceholder = (list) => {
        return <div className="moreSelectOmittedTag">{list?.length}</div>;
      };
      const rProps = {
          treeData: initTreeData(
            (item.fun_tree || {})?.children || [],
            "func_name",
            "func_code"
          ),
          dropdownStyle: {
            maxHeight: 400,
            overflow: "auto",
            minWidth: 200,
          },
          className:
            ((item[column.key] || {})[item.fun_key] || [])?.length > 0
              ? "hasFunValue"
              : null,
          treeDefaultExpandAll: true,
          maxTagCount: "responsive",
          placeholder: `请选择授权的${item.fun_key}`,
          treeCheckable: true,
          maxTagPlaceholder,
          // showCheckedStrategy: SHOW_PARENT,
          onChange: (value) => {
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  if (!_[column.key]) {
                    _[column.key] = {};
                  }
                  _[column.key][item.fun_key] = value;
                }
                return _;
              });
              return [...prev];
            });
          },
          tagRender,
          value: (item[column.key] || {})[item.fun_key],
          // status:
          //   column.require &&
          //   getCheckStatus() &&
          //   ((item[column.key] || {})[item.fun_key] || [])?.length === 0
          //     ? "error"
          //     : "",
        },
        lProps = {
          treeData: initTreeData(
            (item.menu_tree || {})?.children || [],
            "menu_name",
            "menu_code"
          ),
          className:
            ((item[column.key] || {})[item.menu_key] || [])?.length > 0
              ? "hasMenuValue"
              : null,
          treeDefaultExpandAll: true,
          maxTagCount: "responsive",
          dropdownStyle: {
            maxHeight: 400,
            overflow: "auto",
            minWidth: 200,
          },
          tagRender,
          maxTagPlaceholder,
          placeholder: `请选择授权的${item.menu_key}`,
          treeCheckable: true,
          // showCheckedStrategy: SHOW_PARENT,
          onChange: (value) => {
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  if (!_[column.key]) {
                    _[column.key] = {};
                  }
                  _[column.key][item.menu_key] = value;
                }
                return _;
              });
              return [...prev];
            });
          },
          value: (item[column.key] || {})[item.menu_key],
          // status:
          //   column.require &&
          //   getCheckStatus() &&
          //   ((item[column.key] || {})[item.menu_key] || [])?.length === 0
          //     ? "error"
          //     : "",
        };

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <TreeSelect
            {...lProps}
            style={{ width: "calc(50% - 5px)", "--text": "111" }}
          />
          <TreeSelect {...rProps} style={{ width: "calc(50% - 5px)" }} />
        </div>
      );
    }
    if (column.key === "item_alert_value") {
      return (
        <Input
          value={item[column.key]}
          disabled={disabled}
          placeholder={placeholder}
          style={{ width: "100%" }}
          status={
            column.require &&
            getCheckStatus() &&
            (item[column.key] === null ||
              item[column.key] === undefined ||
              item[column.key] === "")
              ? "error"
              : ""
          }
          // max={99999}
          onBlur={() => {
            if (item[column.key] === "-") {
              setInfo((prev) => {
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    _[column.key] = "";
                  }
                  return _;
                });
                return [...prev];
              });
            }
          }}
          onChange={(e) => {
            let value = e.target.value?.replace(/[^\-?\d]/g, "")?.slice(0, 5);
            if (value === "-") {
              value = "-";
            } else if (value <= -1) {
              value = "-1";
            } else {
              value = value ? parseInt(value)?.toString() : "";
              if (value === "NaN") {
                value = "0";
              }
            }
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  _[column.key] = value;
                }
                return _;
              });
              return [...prev];
            });
          }}
        ></Input>
      );
    }
    return (
      <Input
        value={item[column.key]}
        disabled={disabled}
        placeholder={placeholder}
        status={
          column.require &&
          getCheckStatus() &&
          (item[column.key] === null ||
            item[column.key] === undefined ||
            item[column.key] === "")
            ? "error"
            : ""
        }
        maxLength={100}
        onChange={(e) => {
          setInfo((prev) => {
            prev = prev.map((_, _index) => {
              if (index === _index) {
                _[column.key] = e.target.value;
              }
              return _;
            });
            return [...prev];
          });
        }}
      ></Input>
    );
  };

  const createFunc = () => {
    const params = {};
    if (!productId) {
      message.info("请选择产品名称");
      return;
    }
    if (!versionType) {
      message.info("请选择版本类型");
      return;
    }
    //授权/订阅方案
    if (!planId) {
      message.info("请选择授权/订阅方案");
      return;
    }
    const item = planList?.find(
      (_) => _?.ver_info.subs_plan_id + "~~~" + _?.ver_info.ver_no === planId
    );

    params.subs_plan_id = item?.ver_info.subs_plan_id;
    params.ver_no = item?.ver_info.ver_no;
    params.prd_id = item?.subs_plan_info.prd_id;
    params.subs_plan_name = item?.ver_info.subs_plan_name;
    params.subs_plan_desc = item?.ver_info.subs_plan_desc;
    params.subs_plan_ver_id = item?.ver_info.subs_plan_ver_id;

    //企业编码
    if (!companyCode) {
      message.info("请输入企业编码");
      return;
    }
    params.co_code = companyCode;
    //企业名称
    if (!companyName) {
      message.info("请输入企业名称");
      return;
    }
    params.co_name = companyName;
    //ESN码
    if (!esn) {
      message.info("请输入ESN码");
      return;
    }
    params.esn_code = esn;
    //产品方案描述
    params.license_desc = describe;

    const cutOffItem = getInfo()?.find(
      (_) => _.dic_item_name === "授权截止日期"
    );
    params.license_deadline_time = cutOffItem.item_auth_value;
    //许可项
    let list = JSON.parse(JSON.stringify(getInfo()));
    let flag = true;
    params.subs_plan_item = list?.map((item, index) => {
      licenseColumns?.forEach((column) => {
        if (column?.require) {
          if (column.key === "item_alert_value" && item.alert_value) {
            return;
          }
          if (item.type === "more_select" && column.key === "item_auth_value"){
            return
          }
          // if (item.type === "more_select" && column.key === "item_auth_value") {
          //   const value = item[column.key] || {};
          //   if((value[item.menu_key] || [])?.length === 0){
          //     flag = false;
          //   }
          //   if((value[item.fun_key] || [])?.length === 0){
          //     flag = false;
          //   }
          // }
          if (
            item[column.key] === null ||
            item[column.key] === undefined ||
            item[column.key] === ""
          ) {
            flag = false;
          }
        }
      });
      if (item.type === "more_select") {
        let value = item.item_auth_value || ""
        if(!value){
          let obj = {};
          obj[item.fun_key] = [];
          obj[item.menu_key] = [];
          value = obj
        }
        item.item_auth_value = JSON.stringify(value);
      }
      return {
        ...item,
      };
    });
    console.log("---------", params);
    if (!flag) {
      message.info("请填写完整订阅许可项");
      setCheckStatus(true);
      return;
    }
    apis?.addLicense(params).then((res) => {
      console.log(res);
      if (res.code === 200) {
        // message.success("创建成功");
        setEditShow(false);
        getDataFunc();
      } else {
        message.error(res.message);
      }
    });
  };

  return (
    <div className="editHtmlContent">
      <div className="itemTitle">
        <span></span>
        {type}软件许可
      </div>
      <div className="itemTitle2">
        <span>
          01<i></i>
        </span>
        订阅信息
      </div>
      {/* 订阅信息 */}
      <div className="subscriptionItemsContent">
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">产品名称</span>
          {type === "申请" ? (
            <Select
              style={{
                width: "500px",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setProductId(value);
                setPlanId(null);
                setLicenseItemsList([]);
                setTimeout(() => {
                  getPlanListFunc();
                }, 50);
              }}
              value={productId}
              options={productList?.map((_) => ({
                value: _.prd_name,
                label: _.prd_name,
              }))}
              placeholder="请选择产品名称"
            />
          ) : (
            <div>{(selectItem?.product || {})?.prd_name}</div>
          )}
        </div>
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">版本类型</span>
          {type === "申请" ? (
            <Select
              style={{
                width: "500px",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setVersionType(value);
                setPlanId(null);
                setLicenseItemsList([]);
                setTimeout(() => {
                  getPlanListFunc();
                }, 50);
              }}
              value={versionType}
              options={versionTypeList}
              placeholder="请选择版本类型"
            />
          ) : (
            <div>{(selectItem?.subs_plan || {})?.version_type_name}</div>
          )}
        </div>
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">授权/订阅方案</span>
          {type === "申请" ? (
            <Select
              style={{
                width: "500px",
              }}
              notFoundContent={
                getProductId() && getVersionType() ? undefined : (
                  <div
                    style={{
                      width: "100%",
                      padding: "10px",
                      textAlign: "center",
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  >
                    请先选择产品名称和版本类型
                  </div>
                )
              }
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setCheckStatus(false);
                setPlanId(value);
                const item = planList?.find(
                  (_) =>
                    _?.ver_info.subs_plan_id + "~~~" + _?.ver_info.ver_no ===
                    value
                );
                getLicenseItemsList(
                  item.subs_plan_info?.prd_co_id,
                  item.subs_plan_item
                );
              }}
              value={planId}
              options={planList?.map((_) => ({
                value: _?.ver_info.subs_plan_id + "~~~" + _?.ver_info.ver_no,
                label: (
                  <div className="verLabelBox">
                    {_?.ver_info.subs_plan_name}
                    <span className="verNo">({_?.ver_info?.ver_no})</span>
                  </div>
                ),
                key: _?.ver_info.subs_plan_id + "~~~" + _?.ver_info.ver_no,
              }))}
              placeholder="请选择订阅方案"
            />
          ) : (
            <div style={{ width: "calc(100% - 60px)" }}>
              <Paragraph
                style={{ marginBottom: "0px" }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title:
                      ((selectItem?.license || {})?.license_file || {})
                        ?.subs_plan_name +
                      `(${(selectItem?.subs_plan || {})?.cur_ver_no})`,
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <div className="verLabelBox">
                    {
                      ((selectItem?.license || {})?.license_file || {})
                        ?.subs_plan_name
                    }
                    <span className="verNo">
                      ({(selectItem?.subs_plan || {})?.cur_ver_no})
                    </span>
                  </div>

                  {/* {(selectItem?.subs_plan || {})?.prd_co_name} */}
                </div>
              </Paragraph>
            </div>
          )}
        </div>
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">企业编码</span>
          {type === "查看" ? (
            <div style={{ width: "calc(100% - 60px)" }}>
              <Paragraph
                style={{ marginBottom: "0px" }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title: (selectItem?.license || {})?.co_code,
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {(selectItem?.license || {})?.co_code}
                </div>
              </Paragraph>
            </div>
          ) : (
            <Input
              style={{ width: "500px" }}
              placeholder="请输入企业编码"
              maxLength={100}
              value={companyCode}
              onChange={(e) => {
                setCompanyCode(e.target.value);
              }}
            ></Input>
          )}
        </div>
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">企业名称</span>
          {type === "查看" ? (
            <div style={{ width: "calc(100% - 60px)" }}>
              <Paragraph
                style={{ marginBottom: "0px" }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title: (selectItem?.license || {})?.co_name,
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {(selectItem?.license || {})?.co_name}
                </div>
              </Paragraph>
            </div>
          ) : (
            <Input
              style={{ width: "500px" }}
              placeholder="请输入企业名称"
              maxLength={100}
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            ></Input>
          )}
        </div>
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">ESN码</span>
          {type === "查看" ? (
            <div style={{ width: "calc(100% - 60px)" }}>
              <Paragraph
                style={{ marginBottom: "0px" }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title: ((selectItem?.license || {})?.license_file || {})
                      ?.esn_code,
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {((selectItem?.license || {})?.license_file || {})?.esn_code}
                </div>
              </Paragraph>
            </div>
          ) : (
            <Input
              style={{ width: "500px" }}
              placeholder="请输入ESN码"
              value={esn}
              maxLength={100}
              onChange={(e) => {
                setEsn(e.target.value);
              }}
            ></Input>
          )}
        </div>
        <div className="subscriptionItem" style={{ width: "100%" }}>
          <i></i>
          <span className="itemLabel">描述</span>
          {type === "查看" ? (
            <div style={{ width: "calc(100% - 60px)" }}>
              <Paragraph
                style={{ marginBottom: "0px" }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title: (selectItem?.license || {})?.license_desc,
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {(selectItem?.license || {})?.license_desc}
                </div>
              </Paragraph>
            </div>
          ) : (
            <Input
              style={{ width: "500px" }}
              placeholder="请输入描述"
              maxLength={100}
              value={describe}
              onChange={(e) => {
                setDescribe(e.target.value);
              }}
            ></Input>
          )}
        </div>
        {type === "查看" && (
          <div className="subscriptionItem">
            <i></i>
            <span className="itemLabel">申请人</span>
            <div>{(selectItem?.license || {})?.user_name}</div>
          </div>
        )}
        {type === "查看" && (
          <div className="subscriptionItem">
            <i></i>
            <span className="itemLabel">申请时间</span>
            <div>{(selectItem?.license || {})?.created_time}</div>
          </div>
        )}
      </div>
      {/* ---- */}
      <div className="itemTitle2">
        <span>
          02<i></i>
        </span>
        订阅许可项
      </div>
      {/* 订阅许可项 */}
      <div className="subscriptionLicenseItemsContent">
        <div className="subscriptionLicenseItemsHeader">
          {licenseColumns?.map((item, index) => (
            <div
              className="headerItem"
              style={{ width: item.width }}
              key={index}
            >
              <i>{item?.require ? "*" : ""}</i>
              {item.name}
            </div>
          ))}
          {<div className="addBox"></div>}
        </div>
        {licenseItemsList?.length > 0 || type === "查看" ? (
          info?.map((item, index) => (
            <div className="subscriptionLicenseItemLine" key={index}>
              {licenseColumns?.map((column) => (
                <div
                  className="itemBox"
                  style={{ width: column.width }}
                  key={column.key}
                >
                  {initItem(item, column, index)}
                </div>
              ))}
              {type !== "查看" &&
                (item.require ? (
                  <div style={{ width: "14px" }}></div>
                ) : (
                  <MinusCircleFilled
                    className="deleteIcon"
                    onClick={() => {
                      MessageModal({
                        type: "warning",
                        description: "确定要删除此条数据吗？",
                        // title: "删除",
                        onOk: () => {
                          if (!item.isNew) {
                            setDeleteList((prev) => {
                              return [...prev, item];
                            });
                          }
                          setInfo((prev) => {
                            prev = prev.filter((_, _index) => index !== _index);
                            return [...prev];
                          });
                        },
                      });
                    }}
                  />
                ))}
            </div>
          ))
        ) : (
          <div className="subscriptionLicenseEmptyItem">
            选择授权/订阅方案后配置许可项
          </div>
        )}
      </div>
      {/* ---- */}
      <div className="subscriptionLicenseBtnBox">
        <Button
          onClick={() => {
            setEditShow(false);
          }}
        >
          {type === "查看" ? "关闭" : "取消"}
        </Button>
        {type !== "查看" && (
          <Button
            style={{ marginLeft: "24px" }}
            type="primary"
            onClick={createFunc}
          >
            申请
          </Button>
        )}
      </div>
    </div>
  );
};
export default EditHtml;
