import { useEffect, useMemo, useState } from "react";
import "./index.scss";
import {
  message,
  Button,
  Select,
  Tooltip,
  Input,
  DatePicker,
  Typography,
  TreeSelect,
} from "antd";
import { licenseColumns, historyColumns } from "../../../LicenseColumnsConfig";
import { useGetState } from "ahooks";
import apis from "../../../apis";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { setBreadcrumbList } from "../../../store/menuInfo/menuInfo";
import debounce from "lodash/debounce";

const { Paragraph, Text } = Typography;

const EditHtml = (props) => {
  const {
    type,
    selectItem = {},
    setEditShow,
    getDataFunc,
    personalEnterprise,
  } = props;

  const [info, setInfo, getInfo] = useGetState([]);
  const [licenseItemsList, setLicenseItemsList] = useState([]);

  const [companys, setCompanys] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  const [versionTypeList, setVersionTypeList, getVersionTypeList] = useGetState(
    []
  );
  const [versionType, setVersionType, getVersionType] = useGetState(null);

  const [productList, setProductList] = useState([]);
  const [productId, setProductId, getProductId] = useGetState(null);

  const [planId, setPlanId, getPlanId] = useGetState(null);
  const [planList, setPlanList, getPlanList] = useGetState([]);

  const [historyEnterprise, setHistoryEnterprise] = useState([]);

  const [startDate, setStartDate, getStartDate] = useGetState(null);
  const [minDate, setMinDate, getMinDate] = useGetState(null);
  const [checkStatus, setCheckStatus, getCheckStatus] = useGetState(false);
  const dispatch = useDispatch();
  const [searchLoading, setSearchLoading] = useState(false);

  //初始化许可项
  const initLicenseItems = (data, defaultInfo) => {
    const planItem = getPlanList()?.find(
      (_) => _?.subs_plan_info?.subs_plan_id === getPlanId()
    );
    let list = [];
    if (defaultInfo) {
      defaultInfo?.forEach((item) => {
        const dicItem = data?.find(
          (_) => _.dic_item_code === item.subs_plan_item_type_code
        );
        if (dicItem) {
          if (dicItem.dic_item_name === "授权截止日期") {
            if (type === "续订") {
              item.edit = true;
              const dateItem = selectItem?.co_subs_plan_item?.find(
                (_) => _?.subs_plan_item_type_code === "AUTH_DEADLINE_TIME"
              );
              //若为个人试用版或者企业试用版 或者 续订 全部设置为空,取当前时间加默认时长
              if (
                (selectItem?.subs_plan || {})?.version_type_code ===
                  "ENTERPRISE_TRIAL_EDITION" ||
                (selectItem?.subs_plan || {})?.version_type_code ===
                  "PERSONAL_TRIAL_EDITION" ||
                type === "续订"
              ) {
                item.item_auth_value = null;
              } else {
                item.item_auth_value = dateItem?.item_auth_value;
              }
            }
            if (type === "新增") {
              item.edit = true;
            }
            if (type === "升级") {
              item.dateType = true;
              const __item =
                (selectItem?.co_subs_plan_item || [])?.find(
                  (__) => __.subs_plan_item_name === "授权截止日期"
                ) || {};
              item.item_auth_value = __item?.item_auth_value;
            }
            if (item.item_auth_value) {
              // setStartDate(item.item_auth_value);
              // setMinDate(item.item_auth_value);
            } else {
              if (planItem?.ver_info?.subs_default_dur) {
                let interval = "d";
                if (planItem?.ver_info?.subs_dur_unit === "年") {
                  interval = "y";
                } else if (planItem?.ver_info?.subs_dur_unit === "月") {
                  interval = "M";
                }
                item.item_auth_value = dayjs(new Date())
                  .add(planItem?.ver_info?.subs_default_dur, interval)
                  .format("YYYY-MM-DD");
              }
            }
            console.log(planItem, item.item_auth_value);
          }
          item = { ...item, ...dicItem, seq: item.seq };
          list.push(item);
        }
      });
    }
    console.log(list);
    setInfo(list);
  };

  //获取许可项列表
  const getLicenseItemsList = (co_id, defaultInfo) => {
    apis.getLicenseItemsList(co_id).then((res) => {
      console.log("🚀 ~ 许可项列表:", res);
      if (res.code === 200) {
        setLicenseItemsList(res.data);
        initLicenseItems(res.data, defaultInfo);
      }
    });
  };

  //获取企业列表
  const getCompanyList = (company_name) => {
    if (!company_name) {
      setCompanys([]);
      return;
    }
    setSearchLoading(true);
    apis
      .getCompanyList({
        company_name: company_name,
        version_type: personalEnterprise
          ? "PERSONAL"
          : "ENTERPRISE,SUPER_GROUP",
      })
      .then((res) => {
        if (res.code === 200) {
          setCompanys(res.data || []);
        }
        setSearchLoading(false);
      });
  };

  const debounceFetcher = useMemo(() => {
    return debounce(getCompanyList, 500);
  }, []);

  //获取版本类型
  const getVersionTypeListFunc = () => {
    apis
      .getVerType(personalEnterprise ? "PERSONAL" : "ENTERPRISE")
      .then((res) => {
        if (res.code === 200) {
          const list =
            Object.keys(res.data || {})?.map((key) => {
              let item = {
                value: (res.data[key] || {})?.dic_item_code,
                label: (res.data[key] || {})?.dic_item_name,
              };
              return item;
            }) || [];
          setVersionTypeList(list);
        }
      });
  };

  //获取产品列表
  const getProductList = () => {
    apis.getProductList().then((res) => {
      console.log("🚀 ~ 产品列表:", res);
      if (res.code === 200) {
        setProductList(res.data);
      }
    });
  };

  //获取订阅方案
  const getPlanListFunc = () => {
    let typeCode = getVersionType();
    if (type === "升级") {
      typeCode = ((typeCode || "")?.split("_") || [])?.at(0);
    }
    const params = {
      product_name: getProductId(),
      version_type_code: typeCode,
      subs_plan_status_code: "VALID",
      page: 1,
      page_size: 9999,
    };
    apis.getPlanList(params).then((res) => {
      console.log("🚀 ~ 订阅方案:", res);
      if (res.code === 200) {
        setPlanList(res.data?.result || []);
        //type === "新增" ||  type === "升级"
        if (type === "续订") {
          if ((res.data?.result || [])?.length === 0) {
            return;
          }
          const item = (res.data?.result || [])?.find(
            (_) =>
              _?.ver_info?.subs_plan_id ===
              (selectItem?.subs_plan || {})?.subs_plan_id
          );
          getLicenseItemsList(
            (item?.subs_plan_info || {})?.prd_co_id,
            item?.subs_plan_item || []
          );
        }
      } else {
        message.error(res.message);
      }
    });
  };

  //获取历史
  const getHistoryEnterprise = () => {
    apis
      .getHistoryEnterprise({
        co_name: selectItem?.co_info?.co_name,
        prd_id: selectItem?.co_info?.prd_id,
      })
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          setHistoryEnterprise(res.data);
        }
      });
  };

  useEffect(() => {
    if (type === "新增") {
      getVersionTypeListFunc();
      getProductList();
      setInfo([]);
    }
    if (type === "续订" || type === "升级") {
      console.log(selectItem);
      setCompanyId(selectItem?.co_info?.co_id);
      setCompanyName(selectItem?.co_info?.co_name);
      setPlanId(type === "升级" ? null : selectItem?.co_info?.subs_plan_id);
      // getLicenseItemsList(
      //   (selectItem?.subs_plan || {})?.prd_co_id,
      //   selectItem?.co_subs_plan_item || []
      // );
      setVersionType(selectItem?.subs_plan?.version_type_code);
      setProductId(selectItem?.product_name?.prd_name);
    }
    if (type === "新增" || type === "续订" || type === "升级") {
      // getCompanyList();
    }
    if (type === "续订" || type === "升级") {
      setTimeout(() => {
        getPlanListFunc();
      }, 100);
    }
    if (type === "查看") {
      getHistoryEnterprise();
      getLicenseItemsList(
        (selectItem?.subs_plan || {})?.prd_co_id,
        selectItem?.co_subs_plan_item || []
      );
    }
    // setDefaultInfo(JSON.parse(JSON.stringify(selectItem || {})));
    dispatch(
      setBreadcrumbList([
        {
          title: (
            <span
              className="backSpan"
              onClick={() => {
                setEditShow(false);
              }}
            >
              {personalEnterprise ? "个人订阅管理" : "企业订阅管理"}
            </span>
          ),
        },
        {
          title: type === "新增" ? "订阅" : type === "查看" ? "查看订阅" : type,
        },
      ])
    );
  }, [type]);

  const initItem = (item, column, index) => {
    let typeCode = getVersionType();
    if (
      (item.edit ||
        typeCode === "ENTERPRISE_CTO_EDITION" ||
        typeCode === "PERSONAL_CTO_EDITION") &&
      column.key === "item_auth_value"
    ) {
      if (item.dateType) {
        return (
          <div>
            <Paragraph
              style={{ marginBottom: "0px" }}
              ellipsis={{
                rows: 2,
                tooltip: {
                  title: item[column.key] || "-",
                },
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                }}
              >
                {item[column.key] ?? "-"}
              </div>
            </Paragraph>
          </div>
        );
      } else if (item.edit) {
        const disabledDate = (current) => {
          let time = getMinDate() || new Date();
          if (new Date(time)?.getTime() < new Date()?.getTime()) {
            time = new Date();
          }
          return current && current < dayjs(time).endOf("day");
        };
        return (
          <DatePicker
            style={{ width: "100%" }}
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            value={item[column.key] ? dayjs(item[column.key]) : null}
            onChange={(value, dateString) => {
              setInfo((prev) => {
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    _[column.key] = dateString;
                  }
                  return _;
                });
                return [...prev];
              });
            }}
            status={
              column.require && getCheckStatus() && !item[column.key]
                ? "error"
                : ""
            }
          />
        );
      } else if (item.type === "select") {
        return (
          <Select
            style={{ width: "100%" }}
            disabled={item.require}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            status={
              column.require && getCheckStatus() && !item[column.key]
                ? "error"
                : ""
            }
            onChange={(value) => {
              setInfo((prev) => {
                const licenseItem =
                  licenseItemsList?.find((__) => __.dic_item_code === value) ||
                  {};
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    _[column.key] = value;
                    _ = { ...(_ || {}), ...licenseItem };
                  }
                  return _;
                });
                return [...prev];
              });
            }}
            value={item[column.key]}
            options={(item?.select_content || [])?.map((_) => ({
              value: _,
              label: _,
            }))}
            placeholder={`请选择${column?.name}`}
          ></Select>
        );
      } else if (item.type === "more_select") {
        const initTreeData = (list, name_key, code_key) => {
          return list?.map((_) => {
            const __ = {
              title: _[name_key],
              value: _[code_key],
              key: _[code_key],
            };
            if (_.children) {
              __.children = initTreeData(_.children, name_key, code_key);
            }
            return __;
          });
        };

        const tagRender = (props) => {
          return <div className="moreSelectTag">{props?.label}</div>;
        };
        const maxTagPlaceholder = (list) => {
          return <div className="moreSelectOmittedTag">{list?.length}</div>;
        };
        const rProps = {
            treeData: initTreeData(
              (item.fun_tree || {})?.children || [],
              "func_name",
              "func_code"
            ),
            dropdownStyle: {
              maxHeight: 400,
              overflow: "auto",
              minWidth: 200,
            },
            className:
              ((item[column.key] || {})[item.fun_key] || [])?.length > 0
                ? "hasFunValue"
                : null,
            treeDefaultExpandAll: true,
            maxTagCount: "responsive",
            placeholder: `请选择授权的${item.fun_key}`,
            treeCheckable: true,
            maxTagPlaceholder,
            // showCheckedStrategy: SHOW_PARENT,
            onChange: (value) => {
              setInfo((prev) => {
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    if (!_[column.key]) {
                      _[column.key] = {};
                    }
                    _[column.key][item.fun_key] = value;
                  }
                  return _;
                });
                return [...prev];
              });
            },
            tagRender,
            value: (item[column.key] || {})[item.fun_key],
            // status:
            //   column.require &&
            //   getCheckStatus() &&
            //   ((item[column.key] || {})[item.fun_key] || [])?.length === 0
            //     ? "error"
            //     : "",
          },
          lProps = {
            treeData: initTreeData(
              (item.menu_tree || {})?.children || [],
              "menu_name",
              "menu_code"
            ),
            className:
              ((item[column.key] || {})[item.menu_key] || [])?.length > 0
                ? "hasMenuValue"
                : null,
            treeDefaultExpandAll: true,
            maxTagCount: "responsive",
            dropdownStyle: {
              maxHeight: 400,
              overflow: "auto",
              minWidth: 200,
            },
            tagRender,
            maxTagPlaceholder,
            placeholder: `请选择授权的${item.menu_key}`,
            treeCheckable: true,
            // showCheckedStrategy: SHOW_PARENT,
            onChange: (value) => {
              setInfo((prev) => {
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    if (!_[column.key]) {
                      _[column.key] = {};
                    }
                    _[column.key][item.menu_key] = value;
                  }
                  return _;
                });
                return [...prev];
              });
            },
            value: (item[column.key] || {})[item.menu_key],
            // status:
            //   column.require &&
            //   getCheckStatus() &&
            //   ((item[column.key] || {})[item.menu_key] || [])?.length === 0
            //     ? "error"
            //     : "",
          };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <TreeSelect
              {...lProps}
              style={{ width: "calc(50% - 5px)", "--text": "111" }}
            />
            <TreeSelect {...rProps} style={{ width: "calc(50% - 5px)" }} />
          </div>
        );
      } else {
        return (
          <Input
            value={item[column.key]}
            placeholder={`请输入${column?.name}`}
            style={{ width: "100%" }}
            status={
              column.require &&
              getCheckStatus() &&
              (item[column.key] === null ||
                item[column.key] === undefined ||
                item[column.key] === "")
                ? "error"
                : ""
            }
            // max={99999}
            onBlur={() => {
              if (item[column.key] === "-") {
                setInfo((prev) => {
                  prev = prev.map((_, _index) => {
                    if (index === _index) {
                      _[column.key] = "";
                    }
                    return _;
                  });
                  return [...prev];
                });
              }
            }}
            onChange={(e) => {
              let value = e.target.value?.replace(/[^\-?\d]/g, "")?.slice(0, 5);
              if (value === "-") {
                value = "-";
              } else if (value <= -1) {
                value = "-1";
              } else {
                value = value ? parseInt(value)?.toString() : "";
                if (value === "NaN") {
                  value = "0";
                }
              }
              setInfo((prev) => {
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    _[column.key] = value;
                  }
                  return _;
                });
                return [...prev];
              });
            }}
          ></Input>
        );
      }
    }
    if (
      (item.edit ||
        typeCode === "ENTERPRISE_CTO_EDITION" ||
        typeCode === "PERSONAL_CTO_EDITION") &&
      column.key === "item_alert_value"
    ) {
      if (item.dateType) {
        return (
          <div>
            <Paragraph
              style={{ marginBottom: "0px" }}
              ellipsis={{
                rows: 2,
                tooltip: {
                  title: item[column.key] || "-",
                },
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                }}
              >
                {item[column.key] ?? "-"}
              </div>
            </Paragraph>
          </div>
        );
      }
      let disabled = false;
      let placeholder = `请输入${column?.name}`;
      if (item.alert_value) {
        disabled = true;
        placeholder = "不涉及";
      }
      return (
        <Input
          disabled={disabled}
          value={item[column.key]}
          placeholder={placeholder}
          style={{ width: "100%" }}
          // max={99999}
          status={
            column.require &&
            getCheckStatus() &&
            (item[column.key] === null ||
              item[column.key] === undefined ||
              item[column.key] === "")
              ? "error"
              : ""
          }
          onBlur={() => {
            if (item[column.key] === "-") {
              setInfo((prev) => {
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    _[column.key] = "";
                  }
                  return _;
                });
                return [...prev];
              });
            }
          }}
          onChange={(e) => {
            let value = e.target.value?.replace(/[^\-?\d]/g, "")?.slice(0, 5);
            if (value === "-") {
              value = "-";
            } else if (value <= -1) {
              value = "-1";
            } else {
              value = value ? parseInt(value)?.toString() : "";
              if (value === "NaN") {
                value = "0";
              }
            }
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  _[column.key] = value;
                }
                return _;
              });
              return [...prev];
            });
          }}
        ></Input>
      );
    }
    //授权值 且为多选框时
    if (item.type === "more_select" && column.key === "item_auth_value") {
      //code和对应的中文拿出来
      const codeObj = {};
      let count = 1;
      const flatTree = (list, codeKey, nameKey) => {
        list?.forEach((_) => {
          if (_?.children) {
            flatTree(_?.children, codeKey, nameKey);
          }
          codeObj[_[codeKey]] = { name: _[nameKey], index: count };
          count = count + 1;
        });
      };
      flatTree(item.fun_tree?.children || [], "func_code", "func_name");
      flatTree(item.menu_tree?.children || [], "menu_code", "menu_name");
      const funList = ((item?.item_auth_value || {})[item.fun_key] || [])?.sort(
        (a, b) => codeObj[a]?.index - codeObj[b]?.index
      );
      const menuList = (
        (item?.item_auth_value || {})[item.menu_key] || []
      )?.sort((a, b) => codeObj[a]?.index - codeObj[b]?.index);
      //最多显示几个内容
      const max = 1;
      return (
        <div className="moreSelectDiv">
          <div className="menuDiv">
            <div className="typeDiv">{item.menu_key}</div>
            {menuList?.slice(0, max)?.map((_) => (
              <span>{codeObj[_]?.name}</span>
            ))}
            {menuList?.length > max && (
              <Tooltip
                overlayClassName="moreSelectTooltip"
                placement="bottom"
                title={
                  <div className="moreSelectTooltipDiv">
                    {menuList?.slice(max)?.map((_) => (
                      <span>{codeObj[_]?.name}</span>
                    ))}
                  </div>
                }
                color="#fff"
              >
                <div className="countDiv">{menuList?.length - max}</div>
              </Tooltip>
            )}
          </div>
          <div className="funcDiv">
            <div className="typeDiv">{item.fun_key}</div>
            {funList?.slice(0, max)?.map((_) => (
              <span>{codeObj[_]?.name}</span>
            ))}
            {funList?.length > max && (
              <Tooltip
                overlayClassName="moreSelectTooltip"
                placement="bottom"
                title={
                  <div className="moreSelectTooltipDiv">
                    {funList?.slice(max)?.map((_) => (
                      <span>{codeObj[_]?.name}</span>
                    ))}
                  </div>
                }
                color="#fff"
              >
                <div className="countDiv">{funList?.length - max}</div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    }
    //许可项类型 显示中文
    if (column.key === "subs_plan_item_type_code") {
      return (
        <div>
          <Paragraph
            style={{ marginBottom: "0px" }}
            ellipsis={{
              rows: 2,
              tooltip: {
                title: item?.dic_item_name || "-",
              },
            }}
          >
            <div
              style={{
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {item?.dic_item_name || "-"}
            </div>
          </Paragraph>
        </div>
      );
    }
    return (
      <div>
        <Paragraph
          style={{ marginBottom: "0px" }}
          ellipsis={{
            rows: 2,
            tooltip: {
              title: item[column.key] || "-",
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item[column.key] ?? "-"}
          </div>
        </Paragraph>
      </div>
    );
  };

  const initHistoryItem = (item, column, index) => {
    return (
      <div>
        <Paragraph
          style={{ marginBottom: "0px" }}
          ellipsis={{
            rows: 2,
            tooltip: {
              title: column.get(item) || "-",
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {column.get(item) ?? "-"}
          </div>
        </Paragraph>
      </div>
    );
  };

  const createFunc = () => {
    const params = {};
    //产品名称
    if (!companyId) {
      message.info("请选择企业");
      return;
    }
    params.co_id = companyId;
    params.co_name = companyName;
    // //版本类型
    // if (!versionType) {
    //   message.info("请输入版本类型");
    //   return;
    // }
    // params.version_type_code = versionType;
    //产品名称
    // if (!productId) {
    //   message.info("请输入产品名称");
    //   return;
    // }
    // params.prd_id = productId;
    //订阅方案
    if (!planId) {
      message.info("请选择订阅方案");
      return;
    }
    const item = planList?.find((_) => _?.ver_info.subs_plan_id === planId);
    params.subs_plan_id = item?.ver_info.subs_plan_id;
    params.ver_no = item?.ver_info.ver_no;
    params.prd_id = item?.subs_plan_info.prd_id;

    const cutOffItem = getInfo()?.find(
      (_) => _.dic_item_name === "授权截止日期"
    );
    params.subs_deadline_time = cutOffItem.item_auth_value;
    if (!cutOffItem.item_auth_value) {
      message.info("请选择授权截止日期");
      setCheckStatus(true);
      return;
    }
    if (
      cutOffItem.item_alert_value === null ||
      cutOffItem.item_alert_value === undefined ||
      cutOffItem.item_alert_value === ""
    ) {
      setCheckStatus(true);
      message.info("请输入告警阈值（余值）");
      return;
    }
    // if (getStartDate()) {
    //   //之前的截止日期在今天之前的话取今天  否则取 原始的的截止日期
    //   if (moment(getStartDate()).isBefore(new Date())) {
    //     params.subs_stat_time = moment(new Date()).format("YYYY-MM-DD");
    //   } else {
    //     params.subs_stat_time = getStartDate();
    //   }
    // }
    params.pre_subs_plan_id = selectItem?.subs_plan_ver?.subs_plan_id;
    let list = JSON.parse(JSON.stringify(getInfo()));

    let flag = true;
    params.subs_plan_item = list?.map((item, index) => {
      if (!item.delete) {
        licenseColumns?.forEach((column) => {
          if (column?.require) {
            if (column.key === "item_alert_value" && item.alert_value) {
              return;
            }
            if (
              column.key === "item_auth_value" &&
              item.dic_item_name === "授权截止日期"
            ) {
              return;
            }
            if (
              item.type === "more_select" &&
              column.key === "item_auth_value"
            ) {
              return;
            }
            // if (
            //   item.type === "more_select" &&
            //   column.key === "item_auth_value"
            // ) {
            //   const value = item[column.key] || {};
            //   if ((value[item.menu_key] || [])?.length === 0) {
            //     flag = false;
            //   }
            //   if ((value[item.fun_key] || [])?.length === 0) {
            //     flag = false;
            //   }
            // }
            if (
              item[column.key] === null ||
              item[column.key] === undefined ||
              item[column.key] === ""
            ) {
              flag = false;
            }
          }
        });
      }
      if (item.type === "more_select") {
        let value = item.item_auth_value || ""
        if(!value){
          let obj = {};
          obj[item.fun_key] = [];
          obj[item.menu_key] = [];
          value = obj
        }
        item.item_auth_value = JSON.stringify(value);
      }
      return {
        ...item,
      };
    });
    if (!flag) {
      message.info("请填写完整订阅许可项");
      setCheckStatus(true);
      return;
    }
    if (type === "新增") {
      params.operation_type = "add";
    } else if (type === "续订") {
      params.operation_type = "renew";
    } else if (type === "升级") {
      params.operation_type = "upgrades";
    }
    apis.addEnterprise(params).then((res) => {
      console.log(res);
      if (res.code === 200) {
        setEditShow(false);
        getDataFunc();
      } else {
        message.error(res.message);
      }
    });
  };

  const handleSearch = (newValue) => {
    debounceFetcher(newValue);
  };

  const handleChange = (newValue) => {
    const item = companys?.find((_) => _.co_id === newValue);
    setCompanyId(newValue);
    setCompanyName(item?.co_name);
  };

  return (
    <div className="editHtmlContent">
      <div className="itemTitle">
        <span></span>
        {type === "新增" ? "订阅" : type === "查看" ? "查看订阅" : type}
      </div>
      <div className="itemTitle2">
        <span>
          01<i></i>
        </span>
        订阅信息
      </div>
      {/* 订阅信息 */}
      <div className="subscriptionItemsContent">
        <div
          className="subscriptionItem"
          style={{ width: type === "升级" ? "100%" : "50%" }}
        >
          <i>*</i>
          <span className="itemLabel">
            {personalEnterprise ? "用户名称" : "企业名称"}
          </span>
          {type === "新增" ? (
            <Select
              style={{
                width: "500px",
              }}
              loading={searchLoading}
              showSearch
              value={companyId}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              allowClear
              onClear={() => {
                setCompanys([]);
                setCompanyId(null);
                setCompanyName(null);
              }}
              options={(companys || []).map((d) => ({
                value: d.co_id,
                label: (
                  <div className="PersonAndCompanySelectItem">
                    <a className="nameBox" title={d.co_name}>
                      {d.co_name}
                    </a>
                    <span className="codeBox">{d.co_code}</span>
                  </div>
                ),
              }))}
              placeholder={
                personalEnterprise
                  ? "请输入用户编号/名称查询并选择用户"
                  : "请输入企业编码/名称查询并选择企业"
              }
            />
          ) : (
            <div>
              {(selectItem?.co_info || {})?.co_name}(
              {(selectItem?.co_info || {})?.co_code})
            </div>
          )}
        </div>
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">产品名称</span>
          {type === "新增" ? (
            <Select
              style={{
                width: "500px",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setProductId(value);
                setPlanId(null);
                setLicenseItemsList([]);
                setTimeout(() => {
                  getPlanListFunc();
                }, 50);
              }}
              value={productId}
              options={productList?.map((_) => ({
                value: _.prd_name,
                label: _.prd_name,
              }))}
              placeholder="请选择产品名称"
            />
          ) : (
            <div>{(selectItem?.product_name || {})?.prd_name}</div>
          )}
        </div>
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">版本类型</span>
          {type === "新增" ? (
            <Select
              style={{
                width: "500px",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setVersionType(value);
                setPlanId(null);
                setLicenseItemsList([]);
                setTimeout(() => {
                  getPlanListFunc();
                }, 50);
              }}
              value={versionType}
              options={versionTypeList}
              placeholder="请选择版本类型"
            />
          ) : (
            <div>{(selectItem?.subs_plan || {})?.version_type_name}</div>
          )}
        </div>
        {type === "升级" && (
          <div className="subscriptionItem">
            <i></i>
            <span className="itemLabel">当前订阅方案名称</span>
            <div style={{ width: "calc(100% - 60px)" }}>
              <Paragraph
                style={{ marginBottom: "0px" }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title:
                      (selectItem?.subs_plan_ver || {})?.subs_plan_name +
                      `(${(selectItem?.subs_plan_ver || {})?.ver_no})`,
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <div className="verLabelBox">
                    {(selectItem?.subs_plan_ver || {})?.subs_plan_name}
                    <span className="verNo">
                      ({(selectItem?.subs_plan_ver || {})?.ver_no})
                    </span>
                  </div>
                  {/* {(selectItem?.subs_plan_ver || {})?.subs_plan_name} */}
                </div>
              </Paragraph>
            </div>
          </div>
        )}
        <div className="subscriptionItem">
          <i>*</i>
          <span className="itemLabel">
            {type === "升级" ? "新的" : ""}订阅方案名称
          </span>
          {type === "新增" || type === "升级" ? (
            <Select
              style={{
                width: "500px",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              notFoundContent={
                getProductId() && getVersionType() ? undefined : (
                  <div
                    style={{
                      width: "100%",
                      padding: "10px",
                      textAlign: "center",
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  >
                    请先选择产品名称和版本类型
                  </div>
                )
              }
              onChange={(value) => {
                setPlanId(value);
                setCheckStatus(false);
                const item = planList?.find(
                  (_) => _?.ver_info.subs_plan_id === value
                );
                setVersionType(item.subs_plan_info.version_type_code);
                getLicenseItemsList(
                  item.subs_plan_info?.prd_co_id,
                  item.subs_plan_item
                );
              }}
              value={planId}
              options={planList?.map((_) => ({
                value: _?.ver_info.subs_plan_id,
                label: (
                  <div className="verLabelBox">
                    {_?.ver_info.subs_plan_name}
                    <span className="verNo">({_?.ver_info?.ver_no})</span>
                  </div>
                ),
                key: _?.ver_info.subs_plan_id,
              }))}
              placeholder="请选择订阅方案"
            />
          ) : (
            <div style={{ width: "calc(100% - 60px)" }}>
              <Paragraph
                style={{ marginBottom: "0px" }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title:
                      (selectItem?.subs_plan_ver || {})?.subs_plan_name +
                      `(${(selectItem?.subs_plan_ver || {})?.ver_no})`,
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <div className="verLabelBox">
                    {(selectItem?.subs_plan_ver || {})?.subs_plan_name}
                    <span className="verNo">
                      ({(selectItem?.subs_plan_ver || {})?.ver_no})
                    </span>
                  </div>
                  {/* {(selectItem?.subs_plan_ver || {})?.subs_plan_name} */}
                </div>
              </Paragraph>
            </div>
          )}
        </div>
        {type === "查看" && (
          <div className="subscriptionItem">
            <i></i>
            <span className="itemLabel">订阅时间</span>
            <div>{(selectItem?.co_info || {})?.created_time}</div>
          </div>
        )}
        {type === "查看" && (
          <div className="subscriptionItem">
            <i></i>
            <span className="itemLabel">创建人</span>
            <div>{(selectItem?.co_info || {})?.user_name}</div>
          </div>
        )}
      </div>
      {/* ---- */}
      <div className="itemTitle2">
        <span>
          02<i></i>
        </span>
        订阅许可项
      </div>
      {/* 订阅许可项 */}
      <div className="subscriptionLicenseItemsContent">
        <div className="subscriptionLicenseItemsHeader">
          {licenseColumns?.map((item) => (
            <div
              className="headerItem"
              style={{ width: item.width }}
              key={item.name}
            >
              <i>{item?.require ? "*" : ""}</i>
              {item.name}
            </div>
          ))}
        </div>
        {licenseItemsList?.length > 0 || type === "查看" ? (
          info?.map((item, index) => (
            <div className="subscriptionLicenseItemLine" key={index}>
              {licenseColumns?.map((column) => (
                <div
                  className="itemBox"
                  style={{ width: column.width }}
                  key={column.key}
                >
                  {initItem(item, column, index)}
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="subscriptionLicenseEmptyItem">
            选择订阅方案后配置许可项
          </div>
        )}
      </div>
      {/* ---- */}
      {type === "查看" && historyEnterprise?.length > 0 && (
        <>
          <div className="itemTitle2" style={{ marginTop: "24px" }}>
            <span>
              03<i></i>
            </span>
            订阅历史
          </div>
          <div className="subscriptionLicenseItemsContent">
            <div className="subscriptionLicenseItemsHeader">
              {historyColumns?.map((item) => (
                <div className="headerItem" style={{ width: item.width }}>
                  <i>{item?.require ? "*" : ""}</i>
                  {item.name}
                </div>
              ))}
            </div>
            {historyEnterprise?.map((item, index) => (
              <div className="subscriptionLicenseItemLine" key={index}>
                {historyColumns?.map((column) => (
                  <div
                    className="itemBox"
                    style={{ width: column.width }}
                    key={column.key}
                  >
                    {initHistoryItem(item, column, index)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {/* ---- */}
      <div className="subscriptionLicenseBtnBox">
        <Button
          onClick={() => {
            setEditShow(false);
          }}
        >
          {type === "查看" ? "关闭" : "取消"}
        </Button>
        {type !== "查看" && (
          <Button
            style={{ marginLeft: "24px" }}
            type="primary"
            onClick={createFunc}
          >
            {type === "新增" ? "订阅" : type}
          </Button>
        )}
      </div>
    </div>
  );
};
export default EditHtml;
