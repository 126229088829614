import SubscriptionManagement from "../view/SubscriptionManagement";
import SubscriptionScheme from "../view/SubscriptionScheme";
import LicenseManagement from '../view/LicenseManagement';

import HomeIcon from "../assets/首页.svg";
import ProjectManagementIcon from "../assets/项目管理.svg";
import BusinessManagement from "../view/BusinessManagement";

const routers = [
  {
    menu_url: "/personalEnterprise",
    element: <SubscriptionManagement personalEnterprise={true}> </SubscriptionManagement>,
    open_mode: "CURRENT_PAGE",
    menu_name: "个人订阅管理",
    icon: HomeIcon,
  },
  {
    menu_url: "/subscriptionManagement",
    element: <SubscriptionManagement> </SubscriptionManagement>,
    open_mode: "CURRENT_PAGE",
    menu_name: "企业订阅管理",
    icon: HomeIcon,
  },
  {
    menu_url: "/subscriptionScheme",
    element: <SubscriptionScheme> </SubscriptionScheme>,
    open_mode: "CURRENT_PAGE",
    menu_name: "订阅方案",
    icon: ProjectManagementIcon,
  },
  {
    menu_url: "/licenseManagement",
    element: <LicenseManagement> </LicenseManagement>,
    open_mode: "CURRENT_PAGE",
    menu_name: "产品许可",
    icon: ProjectManagementIcon,
  },
  {
    menu_url: "/businessManagement",
    element: <BusinessManagement></BusinessManagement>,
    open_mode: "CURRENT_PAGE",
    menu_name: "企业管理",
    icon: ProjectManagementIcon,
  },
  
];

export default routers;
