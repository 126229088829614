import { useEffect, useState, useRef } from "react";
import "./index.scss";
import {
  Button,
  Input,
  Table,
  message,
  Tooltip,
  DatePicker,
  Typography,
  Select,
} from "antd";
import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import EditHtml from "./EditHtml";
import apis from "../../apis";
import moment from "moment";
import dayjs from "dayjs";
import { saveFile } from "../../unit/unit";
import { useGetState, useSize } from "ahooks";
import { useDispatch } from "react-redux";
import { setBreadcrumbList } from "../../store/menuInfo/menuInfo";
const { Paragraph, Text } = Typography;

const LicenseManagement = (props) => {
  const [selectItem, setSelectItem] = useState({});
  const [handleType, setHandleType] = useState("查看");
  const [editShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [data, setData] = useState([]);
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal, getTotal] = useGetState(0);

  const refTable = useRef(null);
  const size = useSize(refTable);

  const columns = useRef([
    {
      title: "产品名称",
      dataIndex: "prd_name",
      key: "prd_name",
      align: "center",
      render: (text, item) => <>{item?.product?.prd_name}</>,
    },
    {
      title: "版本类型",
      dataIndex: "version_type_name",
      key: "version_type_name",
      align: "center",
      render: (text, item) => <>{item?.subs_plan?.version_type_name}</>,
    },
    {
      title: "授权/订阅方案",
      key: "prd_co_name",
      dataIndex: "prd_co_name",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title:
                ((item?.license || {})?.license_file || {})?.subs_plan_name +
                `(${(item?.subs_plan || {})?.cur_ver_no})`,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            <div className="verLabelBox">
              {((item?.license || {})?.license_file || {})?.subs_plan_name}
              <span className="verNo">
                ({(item?.subs_plan || {})?.cur_ver_no})
              </span>
            </div>
          </div>
        </Paragraph>
      ),
    },
    {
      title: "企业编码",
      key: "co_code",
      dataIndex: "co_code",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.license?.co_code,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.license?.co_code}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "企业名称",
      key: "co_name",
      dataIndex: "co_name",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.license?.co_name,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.license?.co_name}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "描述",
      key: "cur_ver_no",
      dataIndex: "cur_ver_no",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.license?.license_desc,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.license?.license_desc}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "许可截止日期",
      key: "license_deadline_time",
      dataIndex: "license_deadline_time",
      align: "center",
      render: (text, item) => <>{item?.license?.license_deadline_time}</>,
    },
    {
      title: "申请人",
      key: "creator",
      dataIndex: "creator",
      align: "center",
      render: (text, item) => <>{item?.license?.user_name}</>,
    },
    {
      title: "申请时间",
      key: "created_time",
      dataIndex: "created_time",
      align: "center",
      render: (text, item) => <>{item?.license?.created_time}</>,
    },
    {
      title: "操作",
      key: "action",
      width: 80,
      render: (_, record) => (
        <div className="tableTagsBox">
          <Tooltip title="查看">
            <svg
              t="1704265207410"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="4261"
              width="12"
              height="12"
              onClick={() => {
                setHandleType("查看");
                setSelectItem(record);
                setEditShow(true);
              }}
            >
              <path
                d="M896 64H128c-35.296 0-64 28.704-64 64v768c0 35.296 28.704 64 64 64h592a32 32 0 1 0 0-64H128V128h768v592a32 32 0 1 0 64 0V128c0-35.296-28.704-64-64-64z"
                p-id="4262"
              ></path>
              <path
                d="M791.744 746.496A206.752 206.752 0 0 0 832 624c0-114.688-93.312-208-208-208S416 509.312 416 624s93.312 208 208 208a206.752 206.752 0 0 0 122.496-40.256l110.88 110.88a31.904 31.904 0 0 0 45.248 0 31.968 31.968 0 0 0 0-45.248l-110.88-110.88zM480 624c0-79.392 64.608-144 144-144s144 64.608 144 144-64.608 144-144 144-144-64.608-144-144zM800 264a32 32 0 0 0-32-32H256a32 32 0 0 0 0 64h512a32 32 0 0 0 32-32zM256 422.656a32 32 0 0 0 0 64h96a32 32 0 0 0 0-64H256z"
                p-id="4263"
              ></path>
            </svg>
          </Tooltip>
          <Tooltip title="下载">
            <svg
              t="1704349177691"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="3475"
              width="12"
              height="12"
              onClick={() => {
                console.log("下载");
                downLoadFile(record);
              }}
            >
              <path
                d="M321.6 346.5h381c21.4 0 39-17.5 39-39 0-21.4-17.5-39-39-39h-381c-21.4 0-39 17.5-39 39 0 21.4 17.5 39 39 39zM282.6 488.5c0 21.4 17.5 39 39 39H606c21.4 0 39-17.5 39-39 0-21.4-17.5-39-39-39H321.6c-21.5 0-39 17.5-39 39z"
                p-id="3476"
              ></path>
              <path
                d="M908.5 113.9c0-27.6-22.4-50-50-50H166.8c-27.6 0-50 22.4-50 50v794.7c0 27.6 22.4 50 50 50h307.3c20.8-0.3 37.5-17.2 37.5-38s-16.7-37.7-37.5-38H192.8V139.9h639.7v400.8c0 21 17 38 38 38s38-17 38-38V113.9z"
                p-id="3477"
              ></path>
              <path
                d="M805.3 789.6L779 815.9V627.7c0-22.4-18.3-40.7-40.7-40.7-22.4 0-40.7 18.3-40.7 40.7v188.1l-26.3-26.3c-15.8-15.8-41.8-15.8-57.6 0s-15.8 41.8 0 57.6l95.9 95.9c15.8 15.8 41.8 15.8 57.6 0l95.9-95.9c15.9-15.9 15.9-41.8 0-57.6-15.9-15.7-41.9-15.7-57.8 0.1z"
                p-id="3478"
              ></path>
            </svg>
          </Tooltip>
        </div>
      ),
    },
  ]);

  const downLoadFile = (item) => {
    apis.downLoadLicenseFile(item?.license?.license_id).then((res) => {
      console.log(res);
      if (res.status === 200) {
        const fileName = window.decodeURI(
          res.headers["content-disposition"]?.split("filename=")?.at(1)
        );
        saveFile(res.data, fileName);
      }
    });
  };

  const getDataFunc = () => {
    setLoading(true);
    const params = {
      product_name: productName,
      co_name: companyName,
      start_time: startTime && dayjs(startTime).format("YYYY-MM-DD"),
      end_time: endTime && dayjs(endTime).format("YYYY-MM-DD"),
      page: getPageNumber(),
      page_size: getPageSize(),
    };
    apis.getLicenseList(params).then((res) => {
      console.log("🚀 ~ 产品许可列表:", res);
      if (res.code === 200) {
        setData(res.data?.result || []);
        setTotal(res.data?.total_count || 0)
      } else {
        setData([]);
        setTotal(0)
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  const getOneDataFunc = () => {
    setPageNumber(1);
    setPageSize(10);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  };

  //获取产品列表
  const getProductList = () => {
    apis.getProductList().then((res) => {
      console.log("🚀 ~ 产品列表:", res);
      if (res.code === 200) {
        setProductList(res.data);
      }
    });
  };

  useEffect(() => {
    getDataFunc();
    getProductList();
  }, []);

  useEffect(() => {
    if (!editShow) {
      dispatch(
        setBreadcrumbList([
          {
            title: "产品许可",
          },
        ])
      );
    }
  }, [editShow]);

  if (editShow) {
    return (
      <EditHtml
        type={handleType}
        selectItem={selectItem}
        setEditShow={setEditShow}
        getDataFunc={getOneDataFunc}
      ></EditHtml>
    );
  }

  return (
    <div className="licenseManagementContent" ref={refTable}>
      <div className="filterItemsBox">
        <div className="filterItemsContent">
          <div className="filterItem">
            <span>产品名称</span>
            <Select
              style={{
                width: "210px",
              }}
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setProductName(value);
              }}
              value={productName}
              options={productList?.map((_) => ({
                value: _.prd_name,
                label: _.prd_name,
              }))}
              placeholder="请选择产品名称查询"
            />
          </div>
          <div className="filterItem">
            <span>企业名称</span>
            <Input
              allowClear
              style={{ width: "210px" }}
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
              placeholder="请输入企业名称查询"
            ></Input>
          </div>
          <div className="filterItem">
            <span>申请时间</span>
            <DatePicker
              allowClear
              style={{ width: "120px" }}
              value={startTime}
              onChange={(value) => {
                setStartTime(value);
              }}
            />
            &nbsp;&nbsp;~&nbsp;&nbsp;
            <DatePicker
              allowClear
              style={{ width: "120px" }}
              value={endTime}
              onChange={(value) => {
                setEndTime(value);
              }}
            />
          </div>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => {
              if (loading) {
                return;
              }
              getOneDataFunc();
            }}
          >
            查询
          </Button>
        </div>
        <Button
          type="primary"
          icon={<PlusCircleFilled />}
          onClick={() => {
            setHandleType("申请");
            setSelectItem({});
            setEditShow(true);
          }}
        >
          申请软件许可
        </Button>
      </div>
      <div className="tableContent">
        <Table
          loading={loading}
          columns={columns.current}
          dataSource={data}
          className="myFormTable"
          bordered={true}
          pagination={{
            showTotal: (total, range) => "共" + total + "条",
            total: getTotal()
          }}
          onChange={(pageInfo)=>{
            setPageNumber(pageInfo?.current)
            setPageSize(pageInfo?.pageSize)
            setTimeout(() => {
              getDataFunc()
            }, 100);
          }}
          scroll={{
            y: size?.height - 220 + "px",
          }}
          rowKey={() => {
            return Math.random();
          }}
        />
      </div>
    </div>
  );
};
export default LicenseManagement;
