import React from "react";
import ReactDOM from "react-dom/client";
// import "antd/dist/reset.css";
import "./index.scss";
import App from "./App";
import { ConfigProvider, message } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import store from "./store/index";
import { fixArray } from "./unit/unit";
import { Provider } from "react-redux";
import Login from './view/Login/index'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/zh-cn";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {
  defaultTheme,   // 默认主题
} from '@ant-design/compatible';
import request from 'axios'

moment.locale("zh-cn");
dayjs.locale('zh-cn');
fixArray();

defaultTheme.token.colorPrimary = '#0068B2'
defaultTheme.components.Menu.itemHoverColor = '#0068B2';
defaultTheme.components.Menu.itemSelectedColor = '#0068B2';

const url = process.env['REACT_APP_BASE_URL']
  ? `${process.env['REACT_APP_BASE_URL']}/opm/sys/get_app_config`
  : `${origin}/opm/sys/get_app_config`
request.get(url).then((res) => {
  localStorage.setItem('prefix',res.data?.data?.prefix || '')
  localStorage.setItem('sub_url',res.data?.data?.sub_url || '')
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <ConfigProvider locale={zhCN} theme={defaultTheme}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
})
