import { useEffect, useRef, useState, useMemo } from "react";
import "./index.scss";
import {
  Button,
  Input,
  Table,
  Typography,
  Tooltip,
  message,
  Select,
  Form,
  Drawer,
} from "antd";
import {
  PlusCircleFilled,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import EditHtml from "./EditHtml/index";
import apis from "../../apis";
import { useGetState, useSize } from "ahooks";
import { useDispatch } from "react-redux";
import { setBreadcrumbList } from "../../store/menuInfo/menuInfo";
import MessageModal from "../../components/MessageModal";
import debounce from "lodash/debounce";
const { Paragraph, Text } = Typography;

const SubscriptionManagement = (props) => {
  const { personalEnterprise = false } = props;
  const [selectItem, setSelectItem] = useState({});
  const [handleType, setHandleType] = useState("查看");
  const [editShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [enterpriseName, setEnterpriseName] = useState(null);
  const [productName, setProductName] = useState(null);
  const [subPlanName, setSubPlanName] = useState(null);
  const [deleteItem, setDeleteItem, getDeleteItem] = useGetState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchLoading2, setSearchLoading2] = useState(false);

  const [companys, setCompanys] = useState([]);
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();
  const [companyId, setCompanyId, getCompanyId] = useGetState(null);

  const [createBusinessOpen, setCreateBusinessOpen] = useState(false);
  const [userList, setUserList] = useState([]);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal, getTotal] = useGetState(0);

  const [form] = Form.useForm();

  const refTable = useRef(null);
  const size = useSize(refTable);

  const unSubscribeFunc = (item) => {
    setDeleteItem(item);

    MessageModal({
      type: "warning",
      description: "确定要撤销此条订阅吗？",
      title: "撤销",
      onCancel: () => {
        setDeleteItem(null);
      },
      onOk: () => {
        console.log(item);
        setDeleteItem(null);
        apis
          .unSubscribe(
            item.co_info?.co_subs_id,
            item.co_info?.co_id,
            item.co_info?.subs_plan_id
          )
          .then((res) => {
            console.log(res);
            if (res.code === 200) {
              getDataFunc();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
      },
    });
  };

  const columns = useRef([
    {
      title: personalEnterprise ? "用户编号" : "企业编码",
      dataIndex: "co_code",
      key: "co_code",
      align: "center",
      render: (text, item) => <>{item?.co_info?.co_code}</>,
    },
    {
      title: personalEnterprise ? "用户名称" : "企业名称",
      dataIndex: "co_name",
      key: "co_name",
      align: "center",
      render: (text, item) => <>{item?.co_info?.co_name}</>,
    },
    {
      title: "产品名称",
      dataIndex: "prd_name",
      key: "prd_name",
      align: "center",
      render: (text, item) => <>{item?.product_name?.prd_name}</>,
    },
    {
      title: "版本类型",
      dataIndex: "version_type_name",
      key: "version_type_name",
      align: "center",
      render: (text, item) => <>{item?.subs_plan?.version_type_name}</>,
    },
    {
      title: "订阅方案编码",
      dataIndex: "subs_plan_code",
      key: "subs_plan_code",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.subs_plan?.subs_plan_code,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.subs_plan?.subs_plan_code}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "订阅方案名称",
      key: "subs_plan_name",
      dataIndex: "subs_plan_name",
      align: "center",
      render: (text, item) => (
        <Paragraph
          ellipsis={{
            rows: 4,
            tooltip: {
              title: item?.subs_plan_ver?.subs_plan_name,
            },
          }}
        >
          <div
            style={{
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.subs_plan_ver?.subs_plan_name}
          </div>
        </Paragraph>
      ),
    },
    {
      title: "版本",
      key: "cur_ver_no",
      dataIndex: "cur_ver_no",
      align: "center",
      render: (text, item) => <>{item?.co_info?.ver_no}</>,
    },
    {
      title: "订阅截止日期",
      key: "subs_deadline_time",
      dataIndex: "subs_deadline_time",
      align: "center",
      render: (text, item) => <>{item?.co_info?.subs_deadline_time}</>,
    },
    {
      title: "订阅状态",
      key: "prd_co_id",
      dataIndex: "co_subs_status",
      align: "center",
      render: (text, item) => <>{item?.co_info?.co_subs_status}</>,
    },
    {
      title: "企业状态",
      key: "co_status",
      dataIndex: "co_status",
      align: "center",
      render: (text, item) => (
        <span
          style={{
            color: item?.co_info?.co_status !== "正常" ? "red" : "unset",
          }}
        >
          {item?.co_info?.co_status}
        </span>
      ),
    },
    {
      title: "创建人",
      key: "subs_plan_desc",
      dataIndex: "subs_plan_desc",
      align: "center",
      render: (text, item) => <>{item?.co_info?.user_name}</>,
    },
    {
      title: "首次订阅时间",
      key: "subs_plan_desc",
      dataIndex: "subs_plan_desc",
      align: "center",
      render: (text, item) => <>{item?.co_info?.created_time}</>,
    },
    {
      title: "最近订阅时间",
      key: "subs_plan_desc",
      dataIndex: "subs_plan_desc",
      align: "center",
      render: (text, item) => <>{item?.co_info?.updated_time}</>,
    },
    {
      title: "操作",
      key: "action",
      width: 100,
      render: (_, record) => (
        <div className="tableTagsBox">
          <Tooltip title="查看">
            <svg
              t="1704265207410"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="4261"
              width="12"
              height="12"
              onClick={() => {
                setHandleType("查看");
                setSelectItem(JSON.parse(JSON.stringify(record)));
                setEditShow(true);
              }}
            >
              <path
                d="M896 64H128c-35.296 0-64 28.704-64 64v768c0 35.296 28.704 64 64 64h592a32 32 0 1 0 0-64H128V128h768v592a32 32 0 1 0 64 0V128c0-35.296-28.704-64-64-64z"
                p-id="4262"
              ></path>
              <path
                d="M791.744 746.496A206.752 206.752 0 0 0 832 624c0-114.688-93.312-208-208-208S416 509.312 416 624s93.312 208 208 208a206.752 206.752 0 0 0 122.496-40.256l110.88 110.88a31.904 31.904 0 0 0 45.248 0 31.968 31.968 0 0 0 0-45.248l-110.88-110.88zM480 624c0-79.392 64.608-144 144-144s144 64.608 144 144-64.608 144-144 144-144-64.608-144-144zM800 264a32 32 0 0 0-32-32H256a32 32 0 0 0 0 64h512a32 32 0 0 0 32-32zM256 422.656a32 32 0 0 0 0 64h96a32 32 0 0 0 0-64H256z"
                p-id="4263"
              ></path>
            </svg>
          </Tooltip>
          {record?.co_info?.co_status === "正常" && (
            <Tooltip title="续订">
              <svg
                t="1704265355134"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="5249"
                width="12"
                height="12"
                onClick={() => {
                  if (record?.subs_plan?.subs_plan_status_code !== "生效") {
                    message.info(
                      `该方案已${
                        record?.subs_plan?.subs_plan_status_code === "删除"
                          ? "删除"
                          : "暂停"
                      }，无法进行续订`
                    );
                    return;
                  }
                  setHandleType("续订");
                  setSelectItem(JSON.parse(JSON.stringify(record)));
                  setEditShow(true);
                }}
              >
                <path
                  d="M867.388235 173.176471l-102.4-108.42353c-24.094118-30.117647-60.235294-30.117647-84.329411 0l-54.211765 60.235294-457.788235 499.952941c-6.023529 6.023529-6.023529 12.047059-6.02353 18.070589l-36.141176 192.752941c-6.023529 24.094118 12.047059 42.164706 36.141176 36.141176l180.705882-36.141176c6.023529 0 12.047059-6.023529 18.070589-12.047059l427.670588-469.835294 84.329412-90.352941c18.070588-30.117647 18.070588-66.258824-6.02353-90.352941z m-548.141176 608.37647l-126.494118 24.094118 24.094118-132.517647 42.164706-48.188236 102.4 114.447059-42.164706 42.164706z m84.329412-96.37647l-102.4-114.447059 343.341176-373.458824 102.4 108.42353-343.341176 379.482353z m385.505882-415.62353l-108.423529-114.447059 42.164705-48.188235 108.42353 114.447059-42.164706 48.188235zM30.117647 926.117647h963.764706v60.235294h-963.764706z"
                  fill="#222222"
                  p-id="5250"
                ></path>
              </svg>
            </Tooltip>
          )}

          {record?.co_info?.co_status === "正常" && (
            <Tooltip title="升级">
              <svg
                t="1704763538732"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="2410"
                width="12"
                height="12"
                onClick={() => {
                  setHandleType("升级");
                  setSelectItem(JSON.parse(JSON.stringify(record)));
                  setEditShow(true);
                }}
              >
                <path
                  d="M 620.544 760.218 h -196.608 c -33.792 0 -61.44 -27.648 -61.44 -61.44 V 465.51 h -129.024 a 43.008 43.008 0 0 1 -31.3344 -72.4992 L 450.97 130.662 c 18.8416 -19.8656 44.032 -30.72 71.2704 -30.72 s 52.6336 10.8544 71.2704 30.72 l 248.627 262.349 c 11.8784 12.4928 15.1552 30.72 8.192 46.4896 s -22.3232 26.0096 -39.5264 26.0096 h -129.024 v 233.267 c 0.2048 33.9968 -27.2384 61.44 -61.2352 61.44 Z M 247.808 416.358 H 368.64 c 23.7568 0 43.008 19.2512 43.008 43.008 v 239.411 c 0 6.7584 5.5296 12.288 12.288 12.288 h 196.608 c 6.7584 0 12.288 -5.5296 12.288 -12.288 V 459.366 c 0 -23.7568 19.2512 -43.008 43.008 -43.008 h 120.832 L 557.875 164.454 c -18.8416 -19.8656 -52.6336 -19.8656 -71.2704 0 L 247.808 416.358 Z M 645.12 843.776 h -245.76 c -13.5168 0 -24.576 -11.0592 -24.576 -24.576 s 11.0592 -24.576 24.576 -24.576 h 245.76 c 13.5168 0 24.576 11.0592 24.576 24.576 s -11.0592 24.576 -24.576 24.576 Z M 706.56 925.696 h -368.64 c -13.5168 0 -24.576 -11.0592 -24.576 -24.576 s 11.0592 -24.576 24.576 -24.576 h 368.64 c 13.5168 0 24.576 11.0592 24.576 24.576 s -11.0592 24.576 -24.576 24.576 Z"
                  p-id="2411"
                ></path>
              </svg>
            </Tooltip>
          )}
          
          {record?.co_info?.co_status === "正常" && (
            <Tooltip title="撤销">
              <svg
                t="1708496871999"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2338"
                width="12"
                height="12"
                onClick={() => {
                  setSelectItem(JSON.parse(JSON.stringify(record)));
                  unSubscribeFunc(JSON.parse(JSON.stringify(record)));
                }}
              >
                <path
                  d="M289.6384 256H614.4a307.2 307.2 0 1 1 0 614.4H204.8a51.2 51.2 0 0 1 0-102.4h409.6a204.8 204.8 0 1 0 0-409.6H286.0032l59.2384 59.2384A51.2 51.2 0 1 1 272.7936 489.984L128 345.2416a51.2 51.2 0 0 1 0-72.448L272.7936 128a51.2 51.2 0 0 1 72.448 72.3968L289.6384 256z"
                  fill="#666666"
                  p-id="2339"
                ></path>
              </svg>
            </Tooltip>
          )}
        </div>
      ),
    },
  ]);

  //企业订阅列表
  const getDataFunc = () => {
    setLoading(true);
    const params = {
      co_id: getCompanyId(),
      product_name: productName,
      subs_plan_name: subPlanName,
      version_type_code: personalEnterprise ? "PERSONAL" : "ENTERPRISE",
      page: getPageNumber(),
      page_size: getPageSize(),
    };
    apis.getEnterpriseList(params).then((res) => {
      console.log("🚀 ~ 企业订阅列表:", res);
      if (res.code === 200) {
        setData(res.data?.result || []);
        setTotal(res.data?.total_count || 0);
      } else {
        setData([]);
        setTotal(0);
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  const getOneDataFunc = () => {
    setPageNumber(1);
    setPageSize(10);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  };

  //获取企业列表
  const getCompanyList = (company_name) => {
    if (!company_name) {
      setCompanys([]);
      return;
    }
    setSearchLoading(true);
    apis
      .getCompanyList({
        company_name: company_name,
        version_type: personalEnterprise
          ? "PERSONAL"
          : "ENTERPRISE,SUPER_GROUP",
      })
      .then((res) => {
        if (res.code === 200) {
          setCompanys(res.data || []);
        }
        setSearchLoading(false);
      });
  };

  //获取用户列表
  const getUserFunc = (user_name) => {
    if (!user_name) {
      setUserList([]);
      return;
    }
    setSearchLoading2(true);
    apis.getUserList(user_name).then((res) => {
      if (res.code === 200) {
        setUserList(res.data || []);
        // form.resetFields();
        // setTimeout(() => {
        //   setCreateBusinessOpen(true);
        // }, 100);
      }
      setSearchLoading2(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    return debounce(getCompanyList, 500);
  }, []);

  const debounceFetcher2 = useMemo(() => {
    return debounce(getUserFunc, 500);
  }, []);

  //获取产品列表
  const getProductList = () => {
    apis.getProductList().then((res) => {
      console.log("🚀 ~ 产品列表:", res);
      if (res.code === 200) {
        setProductList(res.data);
      }
    });
  };

  useEffect(() => {
    getDataFunc();
    // getCompanyList();
    getProductList();
  }, []);

  useEffect(() => {
    if (!editShow) {
      dispatch(
        setBreadcrumbList([
          {
            title: personalEnterprise? "个人订阅管理" : "企业订阅管理",
          },
        ])
      );
    }
  }, [editShow]);

  if (editShow) {
    return (
      <EditHtml
        type={handleType}
        selectItem={selectItem}
        setEditShow={setEditShow}
        getDataFunc={getOneDataFunc}
        personalEnterprise={personalEnterprise}
      ></EditHtml>
    );
  }

  const handleSearch = (newValue) => {
    debounceFetcher(newValue);
  };

  const handleChange = (newValue) => {
    const item = companys?.find((_) => _.co_id === newValue);
    setCompanyId(newValue);
    setEnterpriseName(item?.co_name);
  };

  //创建企业
  const createBusinessFunc = () => {
    form.validateFields().then((res) => {
      console.log(res);
      apis.createFormalEnterprise(res.userID, res.companyName).then((res) => {
        console.log(res);
        if (res.code === 200) {
          setCreateBusinessOpen(false);
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
    });
  };

  const handleSearch2 = (newValue) => {
    debounceFetcher2(newValue);
  };

  return (
    <div className="subscriptionManagementContent" ref={refTable}>
      <div className="filterItemsBox">
        <div className="filterItemsContent">
          <div className="filterItem">
            <span>
              {personalEnterprise ? "用户编号/名称" : "企业编码/名称"}
            </span>
            <Select
              style={{
                width: "210px",
              }}
              loading={searchLoading}
              showSearch
              value={companyId}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              allowClear
              onClear={() => {
                setCompanys([]);
                setCompanyId(null);
                setEnterpriseName(null);
              }}
              options={(companys || []).map((d) => ({
                value: d.co_id,
                label: (
                  <div className="PersonAndCompanySelectItem">
                    <a className="nameBox" title={d.co_name}>
                      {d.co_name}
                    </a>
                    <span className="codeBox">{d.co_code}</span>
                  </div>
                ),
              }))}
              placeholder={`请输入${
                personalEnterprise ? "用户编号/名称" : "企业编码/名称"
              }查询`}
            />
          </div>
          <div className="filterItem">
            <span>产品名称</span>
            <Select
              style={{
                width: "210px",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setProductName(value);
              }}
              allowClear
              value={productName}
              options={productList?.map((_) => ({
                value: _.prd_name,
                label: _.prd_name,
              }))}
              placeholder="请选择产品名称查询"
            />
          </div>
          <div className="filterItem">
            <span>订阅方案名称</span>
            <Input
              allowClear
              style={{ width: "210px" }}
              value={subPlanName}
              onChange={(e) => {
                setSubPlanName(e.target.value);
              }}
              placeholder="请输入订阅方案名称查询"
            ></Input>
          </div>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => {
              if (loading) {
                return;
              }
              getOneDataFunc();
            }}
          >
            查询
          </Button>
        </div>
        <div>
          {/* {!personalEnterprise && (
            <Button
              type="primary"
              onClick={() => {
                // getUserFunc();
                form.resetFields();
                setUserList([]);
                setTimeout(() => {
                  setCreateBusinessOpen(true);
                }, 100);
              }}
              style={{ marginRight: "10px" }}
            >
              为新客户创建企业
            </Button>
          )} */}
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => {
              setHandleType("新增");
              setSelectItem({});
              setEditShow(true);
            }}
          >
            订阅
          </Button>
        </div>
      </div>
      <div className="tableContent">
        <Table
          loading={loading}
          columns={columns.current}
          dataSource={data}
          className="myFormTable"
          bordered={true}
          rowClassName={(item) => {
            let className = "";
            if (
              item.subs_plan_ver.subs_plan_ver_id ===
                ((getDeleteItem() || {})?.subs_plan_ver || {})
                  ?.subs_plan_ver_id &&
              item.co_info.co_id ===
                ((getDeleteItem() || {})?.co_info || {})?.co_id
            ) {
              className = "selectItem";
            }
            return className;
          }}
          pagination={{
            showTotal: (total, range) => "共" + total + "条",
            total: getTotal(),
          }}
          onChange={(pageInfo) => {
            setPageNumber(pageInfo?.current);
            setPageSize(pageInfo?.pageSize);
            setTimeout(() => {
              getDataFunc();
            }, 100);
          }}
          scroll={{
            y: size?.height - 220 + "px",
          }}
          rowKey={() => {
            return Math.random();
          }}
        />
      </div>

      <Drawer
        title="创建企业"
        width="600px"
        push={false}
        closable={false}
        open={createBusinessOpen}
        onClose={() => {
          setCreateBusinessOpen(false);
        }}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              setCreateBusinessOpen(false);
            }}
          />
        }
        className="createBusinessDrawer"
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              type="primary"
              onClick={() => {
                createBusinessFunc();
              }}
            >
              确定
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setCreateBusinessOpen(false);
              }}
            >
              关闭
            </Button>
          </div>
        }
      >
        <Form form={form} autoComplete="off" layout="vertical">
          <Form.Item
            name="userID"
            label="用户ID"
            key="userID"
            rules={[
              {
                required: true,
                message: "请选择用户",
              },
            ]}
          >
            <Select
              showSearch
              loading={searchLoading2}
              filterOption={false}
              allowClear
              notFoundContent={null}
              onClear={() => {
                form.setFieldsValue({
                  userID: null,
                });
              }}
              options={userList?.map((_) => ({
                value: _.user_id,
                label: _.user_name,
                key: _.user_id,
              }))}
              onSearch={handleSearch2}
              onChange={(value) => {
                const item = userList?.find((_) => _.user_id === value);
                form.setFieldsValue({
                  userID: value,
                  companyName: item.co_name || null,
                });
              }}
              optionRender={(option) => {
                const item =
                  userList?.find((__) => __.user_id === option.value) || {};
                return (
                  <div className="userSelectItem" key={option.key}>
                    <div className="name">{item.user_name}</div>
                    <div className="labelBox">
                      <span className="label">手机号:{item.phone_num}</span>
                      <span className="label">昵称:{item.full_name}</span>
                    </div>
                  </div>
                );
              }}
              placeholder="请选择用户"
            />
          </Form.Item>
          <Form.Item
            name="companyName"
            label="企业名称"
            key="companyName"
            rules={[
              {
                required: true,
                message: "请输入企业名称",
              },
            ]}
          >
            <Input allowClear placeholder="请输入企业名称" />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};
export default SubscriptionManagement;
