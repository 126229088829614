import "./index.scss";
import {
  Button,
  Drawer,
  Input,
  Form,
  Select,
  Table,
  message,
  Tooltip,
  Tag,
  Avatar,
} from "antd";
import { CloseOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useMemo, useState } from "react";
import projectApi from "../../../apis/index";
import { ReactSVG } from "react-svg";
import deleteIcon from "../../../assets/删除.svg";
import debounce from "lodash/debounce";
import { useGetState } from "ahooks";

const { Option } = Select;
const { TextArea } = Input;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        loading={props.creatLoading}
        onClick={() => {
          props.onFinish();
        }}
        type="primary"
      >
        {props.btnText}
      </Button>
      <Button
        className="noBg"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const CreatEnterpriseDrawer = (props) => {
  const { successFunc, selectItem, handleType } = props;
  const [loading, setLoading] = useState(false);
  const [creatLoading, setCreatLoading] = useState(false);
  const [form] = Form.useForm();

  const [userType, setUserType] = useState(1);
  const [userName, setUserName] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [userId, setUserId] = useState(null);
  const [searchLoading2, setSearchLoading2] = useState(false);

  const [userList, setUserList, getUserList] = useGetState([]);
  const [deleteUserList, setDeleteUserList, getDeleteUserList] = useGetState(
    []
  );

  const [optionsList, setOptionsList] = useState([]);

  const [userOptions, setUserOptions] = useState([]);
  const [roleOptions, setRoleOptions, getRoleOptions] = useGetState([]);

  const [disabled, setDisabled] = useState(false);

  const getRole = () => {
    return projectApi.getCoRole().then((res) => {
      let arr = [];
      if (res.code === 200) {
        arr = res.data;
      }
      arr.push({
        dic_item_name: "-",
      });
      // arr = arr.filter((item) => item.dic_item_code !== "CDD_PRJ_ROLE_CREATOR");
      setRoleOptions(arr);
      return res.data;
    });
  };

  const clearAll = () => {
    setUserList([]);
    setUserOptions([]);
    setRoleOptions([]);
    setUserType(1);
    setUserName(null);
    setUserPhone(null);
    setUserId(null);
    setDisabled(false);
    setOptionsList([]);
    setDeleteUserList([]);
    form?.resetFields();
  };

  const getCompanyUsers = (co_id) => {
    projectApi.getCompanyUserList(co_id).then((res) => {
      console.log(res);
      if (res.code === 200) {
        const list = res.data?.map((item) => ({
          id: Math.random(),
          userId: item?.user?.username,
          userPhone: item?.user?.mobile_no,
          userName: item?.full_name,
          userRole: item.role,
          user_id: item?.user?.id,
          co_user_id: item?.co_user_id,
          isEdit: false,
        }));
        console.log(list);
        setUserList(list);
      } else {
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (!props.visible) {
      clearAll();
    } else {
      console.log(selectItem, handleType);
      if (handleType === "查看") {
        setDisabled(true);
      }
      if (selectItem?.co_id) {
        getCompanyUsers(selectItem?.co_id);
      }

      setUserType(1);
      getRole();
      let formObj = { userType: 1 };
      if (handleType === "查看" || handleType === "管理") {
        formObj.enterpriseName = selectItem.co_name;
        formObj.enterpriseDesc = selectItem.co_desc;
      }
      form.setFieldsValue({ ...formObj });
    }
  }, [props.visible]);

  const deleteUser = (item) => {
    setUserList((prev) => {
      let result = prev.filter((_item) => item.id !== _item.id);
      if (!item.isAdd) {
        setDeleteUserList((prev) => {
          const _item = JSON.parse(JSON.stringify(item || {}));
          delete _item.isEdit;
          _item.isDelete = true;
          return [...prev, _item];
        });
      }
      return [...result];
    });
  };

  const userRoleChange = (key, item) => {
    if (
      key === "创建者" &&
      getUserList()?.some((_) => _.userRole === "创建者")
    ) {
      message.error("一个企业只能有一个创建者!");
      return;
    }
    setUserList((prev) => {
      let result = prev.map((_item) => {
        if (item.id === _item.id) {
          let roleInfo = roleOptions.find((item) => item.dic_item_name === key);
          // _item.role_code = key;
          if (!_item.isAdd) {
            _item.isEdit = true;
          }
          _item.userRole = roleInfo?.dic_item_name;
        }
        return _item;
      });
      return [...result];
    });
  };

  const columns = [
    {
      title: "用户ID",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      width: "120px",
      render: (text, item) => <span>{text || "-"}</span>,
    },
    {
      title: "手机号",
      dataIndex: "userPhone",
      key: "userPhone",
      align: "center",
      width: "140px",
      render: (text, item) => <span>{text || "-"}</span>,
    },
    {
      title: "姓名",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      width: "100px",
    },
    {
      title: "角色",
      dataIndex: "userRole",
      key: "userRole",
      width: "150px",
      align: "center",
      render: (text, item) => {
        if (disabled) {
          return <div>{item.userRole}</div>;
        }
        return (
          <Select
            style={{ width: "120px" }}
            defaultValue={item.userRole}
            bordered={false}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode || document.body
            }
            value={item.userRole}
            onChange={(key) => {
              userRoleChange(key, item);
            }}
          >
            {roleOptions.map((item) => (
              <Option key={item.dic_item_name} value={item.dic_item_name}>
                {item.dic_item_name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: "20px",
      render: (text, item) =>
        disabled ? (
          <></>
        ) : (
          <ReactSVG
            src={deleteIcon}
            className="deleteIcon"
            onClick={() => {
              deleteUser(item);
            }}
          ></ReactSVG>
        ),
    },
  ];

  const addUser = () => {
    const { userType, userPhone, userId, userName } = form.getFieldsValue([
      "userType",
      "userPhone",
      "userId",
      "userName",
    ]);

    let fields = [];

    if (userType === 1 && !userPhone) {
      fields.push({ name: "userPhone", errors: ["请输入手机号"] });
    }
    if (userType === 2 && !userId) {
      fields.push({ name: "userId", errors: ["请选择用户"] });
    }
    if (fields.length > 0) {
      return form.setFields(fields);
    }

    let hasUser = getUserList()?.some((item) => {
      if (userType === 1) {
        return item.userPhone === userPhone;
      }
      if (userType === 2) {
        return item.userId === userId;
      }
      return false;
    });
    if (hasUser) {
      message.info("不能添加重复用户");
      return;
    }
    // let roleInfo = roleOptions.find((item) => item.dic_item_code === userRole);
    let userObj = optionsList?.find((__) => __.user_name === userId) || {};
    let userInfo = {
      id: userType + "_" + (userType === 1 ? userPhone : userId),
      userId: userId,
      userPhone:
        userType === 1
          ? userPhone
          : userObj?.phone_num
          ? userObj?.phone_num?.toString()
          : null,
      userName: userName,
      userRole: "-",
      user_id: userObj?.id,
      isAdd: true,
    };
    setUserList((prev) => {
      return [...prev, JSON.parse(JSON.stringify(userInfo))];
    });
    let obj = {
      userPhone: null,
      userId: null,
      userName: null,
    };
    if (!form.getFieldValue("enterpriseName") && userType === 2) {
      obj.enterpriseName = userObj?.co_name || null
    }
    form.setFieldsValue({...obj});
  };

  const bindCompanyUsers = (co_id) => {
    const editUserList = getUserList()?.filter((_) => _.isEdit || _.isAdd);
    let arr = [];
    editUserList.forEach((_) => {
      let roleItem = getRoleOptions()?.find(
        (__) => __.dic_item_name === _.userRole
      );
      const obj = {
        phone: _.userPhone,
        full_name: _.userName,
        role: roleItem?.dic_item_code,
        user_id: _.user_id,
      };
      if (_.isEdit) {
        obj.is_update = true;
      }
      arr.push(obj);
    });
    getDeleteUserList().forEach((_) => {
      let roleItem = getRoleOptions()?.find(
        (__) => __.dic_item_name === _.userRole
      );
      const obj = {
        phone: _.userPhone,
        full_name: _.userName,
        role: roleItem?.dic_item_code,
        user_id: _.user_id,
        co_user_id: _.co_user_id,
        is_delete: true,
      };
      arr.push(obj);
    });

    if (arr?.length === 0) {
      successFunc && successFunc();
      setCreatLoading(false);
      return;
    }
    const params = {
      user_info: JSON.stringify(arr || []),
      co_id,
    };
    projectApi.bindCompanyUsers(params).then((res) => {
      if (res.code === 200) {
        // message.success(res.message);
        message.success(handleType === "管理" ? "修改成功" : "创建成功")
        successFunc && successFunc();
      } else {
        message.error(res.message);
      }
      setCreatLoading(false);
    });
  };

  const onFinish = () => {
    // successFunc && successFunc();
    // return
    form.validateFields().then((values) => {
      const { enterpriseName, enterpriseDesc } = values;
      let len = (getUserList() || [])?.length;
      if (len === 0) {
        message.warning("企业成员不能为空!");
        return;
      }
      let params = {
        co_name: enterpriseName,
        co_desc: enterpriseDesc,
      };
      setCreatLoading(true);
      let api = projectApi.createCompany;
      if (handleType === "管理") {
        params.co_id = selectItem?.co_id;
        api = projectApi.updateCompany;
      }
      api(params).then((res) => {
        if (res.code === 200) {
          // message.success(res.message);
          // successFunc && successFunc();3
          bindCompanyUsers(res.data.co_id);
        } else {
          message.error(res.message);
          setCreatLoading(false);
        }
      });
    });
  };

  //获取用户列表
  const getUserFunc = (user_name) => {
    if (!user_name) {
      setOptionsList([]);
      return;
    }
    setSearchLoading2(true);
    projectApi.getUserList(user_name).then((res) => {
      if (res.code === 200) {
        setOptionsList(res.data || []);
        // form.resetFields();
        // setTimeout(() => {
        //   setCreateBusinessOpen(true);
        // }, 100);
      }
      setSearchLoading2(false);
    });
  };

  const debounceFetcher2 = useMemo(() => {
    return debounce(getUserFunc, 500);
  }, []);

  const handleSearch2 = (newValue) => {
    debounceFetcher2(newValue);
  };

  return (
    <Drawer
      className="creatProjectDrawer"
      width="600px"
      closable={false}
      title={
        handleType === "查看"
          ? "查看企业"
          : handleType === "管理"
          ? "管理企业"
          : "创建企业"
      }
      placement="right"
      push={false}
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        disabled ? null : (
          <BtnBox
            onClose={props.onClose}
            creatLoading={creatLoading}
            onFinish={onFinish}
            btnText={handleType === "管理" ? "修改" : "创建"}
          ></BtnBox>
        )
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="creatProjectForm"
        >
          <Form.Item
            style={{ width: "100%" }}
            label={<span style={{ fontWeight: "bold" }}>企业名称</span>}
            name="enterpriseName"
            rules={[
              {
                required: true,
                message: "请输入企业名称",
              },
            ]}
          >
            <Input
              maxLength={100}
              allowClear
              placeholder="请输入企业名称"
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label={<span style={{ fontWeight: "bold" }}>企业描述</span>}
            name="enterpriseDesc"
          >
            <TextArea
              rows={3}
              maxLength={100}
              placeholder="请输入企业描述"
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label={<span style={{ fontWeight: "bold" }}>企业成员</span>}
            name="user_list"
          >
            <div className="userListBox">
              {disabled ? null : (
                <div className="searchBox">
                  <Form.Item
                    style={{ width: "100px" }}
                    name="userType"
                    label="添加方式"
                    required
                  >
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode || document.body
                      }
                      value={userType}
                      onChange={(value) => {
                        setUserType(value);
                        form.setFieldsValue({
                          userId: null,
                          userPhone: null,
                        });
                      }}
                    >
                      {[
                        {
                          label: "按手机号",
                          value: 1,
                        },
                        {
                          label: "按用户ID",
                          value: 2,
                        },
                      ]?.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* 用户名或姓名 */}
                  {userType === 1 ? (
                    <Form.Item
                      style={{ width: "calc(50% - 120px)" }}
                      name="userPhone"
                      label="请输入手机号"
                      required
                      rules={[
                        { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不对" },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        allowClear
                        placeholder="请输入手机号"
                        value={userPhone}
                        onChange={(e) => {
                          setUserPhone(e.target.value);
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      style={{ width: "calc(50% - 120px)" }}
                      name="userId"
                      label="用户ID"
                      required
                    >
                      <Select
                        showSearch
                        loading={searchLoading2}
                        filterOption={false}
                        allowClear
                        notFoundContent={null}
                        onClear={() => {
                          form.setFieldsValue({
                            userId: null,
                          });
                          // setOptionsList([])
                        }}
                        options={optionsList?.map((_) => ({
                          value: _.user_name,
                          label: _.user_name,
                          key: _.user_name,
                        }))}
                        value={userId}
                        popupMatchSelectWidth={230}
                        onSearch={handleSearch2}
                        onChange={(value) => {
                          const item =
                            optionsList?.find((__) => __.user_name === value) ||
                            {};
                          form.setFieldsValue({
                            userId: value,
                            userName: item.full_name || null,
                          });
                          setUserId(value);
                          // setOptionsList([])
                        }}
                        optionRender={(option) => {
                          const item =
                            optionsList?.find(
                              (__) => __.user_name === option.value
                            ) || {};
                          return (
                            <div className="userSelectItem2" key={option.key}>
                              <div className="name">{item.user_name}</div>
                              <div className="labelBox">
                                <span className="label">
                                  手机号:{item.phone_num}
                                </span>
                                <span className="label">
                                  姓名:{item.full_name}
                                </span>
                              </div>
                            </div>
                          );
                        }}
                        placeholder="请输入用户ID搜索"
                      />
                    </Form.Item>
                  )}

                  {/* 角色 */}
                  <Form.Item
                    style={{ width: "calc(50% - 120px)" }}
                    name="userName"
                    label="姓名"
                  >
                    <Input
                      maxLength={100}
                      allowClear
                      placeholder="请输入姓名"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </Form.Item>
                  {/* 添加按钮 */}
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                      addUser();
                    }}
                  >
                    添加
                  </Button>
                </div>
              )}

              <Table
                showHeader={userList?.length === 0 ? false : true}
                // scroll={
                //   userList.length > 10
                //     ? {
                //         y: 410,
                //       }
                //     : null
                // }
                pagination={false}
                columns={columns}
                dataSource={userList}
                className="myTable userTable"
                loading={loading}
              />
            </div>
          </Form.Item>
        </Form>
      </Scrollbars>
    </Drawer>
  );
};

export default CreatEnterpriseDrawer;
