import React from "react";
import ReactDOM from "react-dom/client";
const wrapper = (component) => {
  // 销毁组件
  const destoryDialog = (element, root) => {
    root.unmount();
    if (element.parentNode) {
      setTimeout(() => {
        element.parentNode.removeChild(element);
      }, 300);
    }
  };
  // 渲染组件
  const render = ({ element, component, config }) => {
    const root = ReactDOM.createRoot(element);
    const comInstance = React.createElement(component, {
      ...config,
      key: "div",
      closeDialog: () => {
        destoryDialog(element, root);
      },
    });
    root.render(comInstance);
  };
  return function (config) {
    // 挂载div
    const element = document.createElement("div");
    render({ element, component, config });
    document.getElementsByTagName("body")[0].appendChild(element);
  };
};

export default wrapper;
