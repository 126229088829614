import { useEffect, useRef, useState } from "react";
import "./index.scss";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import {
  Input,
  Button,
  Select,
  message,
  TreeSelect,
  Tooltip,
  Typography,
  Spin,
  InputNumber,
} from "antd";
import { licenseColumns } from "../../../LicenseColumnsConfig";
import apis from "../../../apis";
import { useGetState } from "ahooks";
import MessageModal from "../../../components/MessageModal";
import { useDispatch } from "react-redux";
import { setBreadcrumbList } from "../../../store/menuInfo/menuInfo";

const { Paragraph, Text } = Typography;

const EditHtml = (props) => {
  const { type, selectItem = {}, setEditShow, getDataFunc } = props;

  const [defaultInfo, setDefaultInfo] = useState([]);

  const [info, setInfo, getInfo] = useGetState([]);
  const [deleteList, setDeleteList, getDeleteList] = useGetState([]);
  const [productList, setProductList, getProductList] = useGetState([]);
  const [productId, setProductId, getProductId] = useGetState(null);

  const [versionTypeList, setVersionTypeList] = useState([]);
  const [versionType, setVersionType] = useState(null);

  const [templateList, setTemplateList] = useState([]);
  const [templateId, setTemplateId] = useState(null);

  const [subsDefaultDur, setSubsDefaultDur] = useState(null);
  const [subsDurUnit, setSubsDurUnit] = useState("天");

  const [planName, setPlanName] = useState(null);
  const [planDesc, setPlanDesc] = useState(null);
  const [planCode, setPlanCode] = useState(null);

  const [licenseItemsList, setLicenseItemsList] = useState([]);

  const [showData, setShowData] = useState({});
  const [planList, setPlanList] = useState({});
  const [verId, setVerId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [checkStatus, setCheckStatus, getCheckStatus] = useGetState(false);
  const dispatch = useDispatch();

  //获取产品列表
  const getProductListFunc = () => {
    return apis.getProductList().then((res) => {
      console.log("🚀 ~ 产品列表:", res);
      if (res.code === 200) {
        setProductList(res.data);
      }
    });
  };

  //获取历史
  const getPlanList = (id) => {
    apis.getHistoryPlanList(id).then((res) => {
      if (res.code === 200) {
        setPlanList(
          (res.data || [])?.map((item) => {
            return {
              ...item,
              value: item?.ver_info?.subs_plan_ver_id,
              label: item?.ver_info?.ver_no,
            };
          })
        );
      }
    });
  };

  //获取版本类型
  const getVersionTypeList = () => {
    apis.getVerType().then((res) => {
      if (res.code === 200) {
        const list =
          Object.keys(res.data || {})?.map((key) => {
            let item = {
              value: key,
              label: (res.data[key] || {})?.dic_item_name,
            };
            return item;
          }) || [];
        setVersionTypeList(list);
      }
    });
  };

  //获取产品模板列表
  const getTemplateList = () => {
    const item = getProductList().find((_) => _?.prd_id === getProductId());
    console.log(item, getProductId(), getProductList());
    apis.getTemplateList(item?.prd_code).then((res) => {
      console.log("🚀 ~ 产品模板列表:", res);
      if (res.code === 200) {
        let data = res.data;
        const list = Object.keys(data)?.map((key) => ({
          value: key,
          label: data[key],
        }));
        setTemplateList(list);
      }
    });
  };

  //初始化许可项
  const initLicenseItems = (data, defaultInfo) => {
    let list = [];
    if (defaultInfo) {
      defaultInfo?.forEach((item) => {
        const dicItem = data?.find(
          (_) => _.dic_item_code === item.subs_plan_item_type_code
        );
        if (dicItem) {
          if (
            dicItem.dic_item_name === "授权截止日期" ||
            dicItem.dic_item_name === "菜单功能授权"
          ) {
            if (item.seq !== 1 && item.seq !== 2) {
              item.isNew = true;
            }
            item.require = true;
          }
          item = {
            ...item,
            ...JSON.parse(JSON.stringify(dicItem || {})),
            seq: item.seq,
          };
          list.push(item);
        }
      });
    } else {
      data?.forEach((item) => {
        if (
          item.dic_item_name === "授权截止日期" ||
          item.dic_item_name === "菜单功能授权"
        ) {
          // item.require = true;
          item.subs_plan_item_type_code = item.dic_item_code;
          list.push(item);
        }
      });
    }
    console.log(list);
    setInfo(list);
  };

  //获取许可项列表
  const getLicenseItemsList = (co_id, defaultInfo) => {
    apis.getLicenseItemsList(co_id).then((res) => {
      console.log("🚀 ~ 许可项列表:", res);
      if (res.code === 200) {
        setLicenseItemsList(res.data);
        initLicenseItems(
          JSON.parse(JSON.stringify(res.data || {})),
          defaultInfo
        );
      }
    });
  };

  //切换历史
  const changeVer = (id) => {
    setLoading(true);
    setVerId(id);
    const item = planList?.find((_) => _.value === id);
    getLicenseItemsList(
      (item?.subs_plan_info || {})?.prd_co_id,
      item?.subs_plan_item || []
    );
    setShowData(JSON.parse(JSON.stringify(item || {})));
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  useEffect(() => {
    if (type === "新增") {
      getProductListFunc();
      getVersionTypeList();
      setInfo([]);
    }
    if (type === "修改") {
      // getProductListFunc();
      setProductId(
        ((selectItem?.subs_plan_info || {})?.product_info || {})?.prd_id
      );
      setVersionType((selectItem?.subs_plan_info || {})?.version_type_code);
      setTemplateId((selectItem?.subs_plan_info || {})?.prd_co_id);
      setPlanName((selectItem?.ver_info || {})?.subs_plan_name);
      setPlanDesc((selectItem?.ver_info || {})?.subs_plan_desc);
      setPlanCode((selectItem?.subs_plan_info || {})?.subs_plan_code);
      setSubsDefaultDur((selectItem?.ver_info || {})?.subs_default_dur);
      setSubsDurUnit((selectItem?.ver_info || {})?.subs_dur_unit);
      getLicenseItemsList(
        (selectItem?.subs_plan_info || {})?.prd_co_id,
        selectItem?.subs_plan_item || []
      );
    }
    if (type === "修改") {
      getProductListFunc().then((res) => {
        setTimeout(() => {
          getTemplateList();
        }, 100);
      });
    }
    if (type === "查看") {
      getPlanList(selectItem?.subs_plan_info?.subs_plan_id);
      setVerId(selectItem?.ver_info?.subs_plan_ver_id);
      getLicenseItemsList(
        (selectItem?.subs_plan_info || {})?.prd_co_id,
        selectItem?.subs_plan_item || []
      );
    }

    setDefaultInfo(JSON.parse(JSON.stringify(selectItem || {})));
    setShowData(JSON.parse(JSON.stringify(selectItem || {})));
    console.log(selectItem);

    dispatch(
      setBreadcrumbList([
        {
          title: (
            <span
              className="backSpan"
              onClick={() => {
                setEditShow(false);
              }}
            >
              订阅方案
            </span>
          ),
        },
        {
          title: type + "订阅方案",
        },
      ])
    );
  }, [type]);

  const initItem = (item, column, index) => {
    // console.log(item, column, index);
    if (type === "查看") {
      //授权值 且为多选框时
      if (item.type === "more_select" && column.key === "item_auth_value") {
        //code和对应的中文拿出来
        const codeObj = {};
        let count = 1;
        const flatTree = (list, codeKey, nameKey) => {
          list?.forEach((_) => {
            if (_?.children) {
              flatTree(_?.children, codeKey, nameKey);
            }
            codeObj[_[codeKey]] = { name: _[nameKey], index: count };
            count = count + 1;
          });
        };
        flatTree(item.fun_tree?.children || [], "func_code", "func_name");
        flatTree(item.menu_tree?.children || [], "menu_code", "menu_name");
        const funList = (
          (item?.item_auth_value || {})[item.fun_key] || []
        )?.sort((a, b) => codeObj[a]?.index - codeObj[b]?.index);
        const menuList = (
          (item?.item_auth_value || {})[item.menu_key] || []
        )?.sort((a, b) => codeObj[a]?.index - codeObj[b]?.index);
        //最多显示几个内容
        const max = 1;
        return (
          <div className="moreSelectDiv">
            <div className="menuDiv">
              <div className="typeDiv">{item.menu_key}</div>
              {menuList?.slice(0, max)?.map((_) => (
                <span>{codeObj[_]?.name}</span>
              ))}
              {menuList?.length > max && (
                <Tooltip
                  overlayClassName="moreSelectTooltip"
                  placement="bottom"
                  title={
                    <div className="moreSelectTooltipDiv">
                      {menuList?.slice(max)?.map((_) => (
                        <span>{codeObj[_]?.name}</span>
                      ))}
                    </div>
                  }
                  color="#fff"
                >
                  <div className="countDiv">{menuList?.length - max}</div>
                </Tooltip>
              )}
            </div>
            <div className="funcDiv">
              <div className="typeDiv">{item.fun_key}</div>
              {funList?.slice(0, max)?.map((_) => (
                <span>{codeObj[_]?.name}</span>
              ))}
              {funList?.length > max && (
                <Tooltip
                  overlayClassName="moreSelectTooltip"
                  placement="bottom"
                  title={
                    <div className="moreSelectTooltipDiv">
                      {funList?.slice(max)?.map((_, _index) => (
                        <span key={_index}>{codeObj[_]?.name}</span>
                      ))}
                    </div>
                  }
                  color="#fff"
                >
                  <div className="countDiv">{funList?.length - max}</div>
                </Tooltip>
              )}
            </div>
          </div>
        );
      }
      //许可项类型 显示中文
      if (column.key === "subs_plan_item_type_code") {
        return (
          <div>
            <Paragraph
              style={{ marginBottom: "0px" }}
              ellipsis={{
                rows: 2,
                tooltip: {
                  title: item?.dic_item_name || "-",
                },
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                }}
              >
                {item?.dic_item_name || "-"}
              </div>
            </Paragraph>
          </div>
        );
      }
      return (
        <div>
          <Paragraph
            style={{ marginBottom: "0px" }}
            ellipsis={{
              rows: 2,
              tooltip: {
                title: item[column.key] || "-",
              },
            }}
          >
            <div
              style={{
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {item[column.key] ?? "-"}
            </div>
          </Paragraph>
        </div>
      );
    }
    //许可项类型 为单选框
    if (column.key === "subs_plan_item_type_code") {
      let options = licenseItemsList?.map((__) => ({
        value: __.dic_item_code,
        label: __.dic_item_name,
      }));
      //已经选择的不能再选
      options = options?.filter((_) => {
        if (_.value === item.subs_plan_item_type_code) {
          return true;
        }
        return _.label !== "授权截止日期";
        // return !info?.find((__) => __.subs_plan_item_type_code === _.value);
      });

      return (
        <Select
          style={{ width: "100%" }}
          disabled={item.require && !item.isNew}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          status={
            column.require && getCheckStatus() && !item[column.key]
              ? "error"
              : ""
          }
          onChange={(value) => {
            setInfo((prev) => {
              const licenseItem =
                licenseItemsList?.find((__) => __.dic_item_code === value) ||
                {};
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  const obj = JSON.parse(JSON.stringify(licenseItem));
                  obj.isNew = true;
                  obj[column.key] = value;
                  _ = { ...(obj || {}) };
                }
                return _;
              });
              return [...prev];
            });
          }}
          value={item[column.key]}
          options={options}
          placeholder={`请选择${column?.name}`}
        ></Select>
      );
    }
    //
    let disabled = false;
    let placeholder = `请输入${column?.name}`;
    if (column.key === "item_alert_value" && item.alert_value) {
      disabled = true;
      placeholder = "不涉及";
    }
    if (
      item.dic_item_name === "授权截止日期" &&
      column.key === "item_auth_value"
    ) {
      disabled = true;
      placeholder = "订阅时生成";
    }

    if (column.key === "item_auth_value" && item.type === "select") {
      return (
        <Select
          style={{ width: "100%" }}
          disabled={item.require}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          status={
            column.require && getCheckStatus() && !item[column.key]
              ? "error"
              : ""
          }
          onChange={(value) => {
            setInfo((prev) => {
              const licenseItem =
                licenseItemsList?.find((__) => __.dic_item_code === value) ||
                {};
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  _[column.key] = value;
                  _ = { ...(_ || {}), ...licenseItem };
                }
                return _;
              });
              return [...prev];
            });
          }}
          value={item[column.key]}
          options={(item?.select_content || [])?.map((_) => ({
            value: _,
            label: _,
          }))}
          placeholder={`请选择${column?.name}`}
        ></Select>
      );
    }

    if (column.key === "item_auth_value" && item.type === "more_select") {
      const initTreeData = (list, name_key, code_key) => {
        return list?.map((_) => {
          const __ = {
            title: _[name_key],
            value: _[code_key],
            key: _[code_key],
          };
          if (_.children) {
            __.children = initTreeData(_.children, name_key, code_key);
          }
          return __;
        });
      };

      const tagRender = (props) => {
        return <div className="moreSelectTag">{props?.label}</div>;
      };
      const maxTagPlaceholder = (list) => {
        return <div className="moreSelectOmittedTag">{list?.length}</div>;
      };
      const rProps = {
          treeData: initTreeData(
            (item.fun_tree || {})?.children || [],
            "func_name",
            "func_code"
          ),
          dropdownStyle: {
            maxHeight: 400,
            overflow: "auto",
            minWidth: 200,
          },
          className:
            ((item[column.key] || {})[item.fun_key] || [])?.length > 0
              ? "hasFunValue"
              : null,
          treeDefaultExpandAll: true,
          maxTagCount: "responsive",
          placeholder: `请选择授权的${item.fun_key}`,
          treeCheckable: true,
          maxTagPlaceholder,
          // showCheckedStrategy: SHOW_PARENT,
          onChange: (value) => {
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  if (!_[column.key]) {
                    _[column.key] = {};
                  }
                  _[column.key][item.fun_key] = value;
                }
                return _;
              });
              return [...prev];
            });
          },
          tagRender,
          value: (item[column.key] || {})[item.fun_key],
          // status:
          //   column.require &&
          //   getCheckStatus() &&
          //   ((item[column.key] || {})[item.fun_key] || [])?.length === 0
          //     ? "error"
          //     : "",
        },
        lProps = {
          treeData: initTreeData(
            (item.menu_tree || {})?.children || [],
            "menu_name",
            "menu_code"
          ),
          className:
            ((item[column.key] || {})[item.menu_key] || [])?.length > 0
              ? "hasMenuValue"
              : null,
          treeDefaultExpandAll: true,
          maxTagCount: "responsive",
          dropdownStyle: {
            maxHeight: 400,
            overflow: "auto",
            minWidth: 200,
          },
          tagRender,
          maxTagPlaceholder,
          placeholder: `请选择授权的${item.menu_key}`,
          treeCheckable: true,
          // showCheckedStrategy: SHOW_PARENT,
          onChange: (value) => {
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  if (!_[column.key]) {
                    _[column.key] = {};
                  }
                  _[column.key][item.menu_key] = value;
                }
                return _;
              });
              return [...prev];
            });
          },
          value: (item[column.key] || {})[item.menu_key],
          // status:
          //   column.require &&
          //   getCheckStatus() &&
          //   ((item[column.key] || {})[item.menu_key] || [])?.length === 0
          //     ? "error"
          //     : "",
        };

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <TreeSelect
            {...lProps}
            style={{ width: "calc(50% - 5px)", "--text": "111" }}
          />
          <TreeSelect {...rProps} style={{ width: "calc(50% - 5px)" }} />
        </div>
      );
    }
    if (column.key === "item_alert_value") {
      return (
        <Input
          value={item[column.key]}
          disabled={disabled}
          placeholder={placeholder}
          style={{ width: "100%" }}
          status={
            column.require &&
            getCheckStatus() &&
            (item[column.key] === null ||
              item[column.key] === undefined ||
              item[column.key] === "")
              ? "error"
              : ""
          }
          // max={99999}
          onBlur={() => {
            if (item[column.key] === "-") {
              setInfo((prev) => {
                prev = prev.map((_, _index) => {
                  if (index === _index) {
                    _[column.key] = "";
                  }
                  return _;
                });
                return [...prev];
              });
            }
          }}
          onChange={(e) => {
            let value = e.target.value?.replace(/[^\-?\d]/g, "")?.slice(0, 5);
            if (value === "-") {
              value = "-";
            } else if (value <= -1) {
              value = "-1";
            } else {
              value = value ? parseInt(value)?.toString() : "";
              if (value === "NaN") {
                value = "0";
              }
            }
            setInfo((prev) => {
              prev = prev.map((_, _index) => {
                if (index === _index) {
                  _[column.key] = value;
                }
                return _;
              });
              return [...prev];
            });
          }}
        ></Input>
      );
    }
    return (
      <Input
        value={item[column.key]}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={100}
        status={
          column.require &&
          getCheckStatus() &&
          (item[column.key] === null ||
            item[column.key] === undefined ||
            item[column.key] === "")
            ? "error"
            : ""
        }
        onChange={(e) => {
          setInfo((prev) => {
            prev = prev.map((_, _index) => {
              if (index === _index) {
                _[column.key] = e.target.value;
              }
              return _;
            });
            return [...prev];
          });
        }}
      ></Input>
    );
  };

  const createFunc = () => {
    const params = {};
    //产品名称
    if (!productId) {
      message.info("请选择产品名称");
      return;
    }
    params.prd_id = productId;
    //版本类型
    if (!versionType) {
      message.info("请选择版本类型");
      return;
    }
    params.version_type_code = versionType;
    //订阅方案编码
    if (!planCode) {
      message.info("请输入订阅方案编码");
      return;
    }
    params.subs_plan_code = planCode;
    //订阅方案名称
    if (!planName) {
      message.info("请输入订阅方案名称");
      return;
    }
    params.subs_plan_name = planName;
    //产品配置模板
    if (!templateId) {
      message.info("请选择产品配置模板");
      return;
    }
    if (subsDefaultDur) {
      params.subs_default_dur = subsDefaultDur;
    }
    if (subsDurUnit) {
      params.subs_dur_unit = subsDurUnit;
    }
    params.prd_co_id = templateId;
    //产品方案描述
    params.subs_plan_desc = planDesc;
    //许可项
    let list = JSON.parse(JSON.stringify(getInfo()));
    if (getDeleteList()?.length > 0) {
      list = list?.concat(
        getDeleteList()?.map((_) => {
          _.delete = true;
          return _;
        })
      );
    }
    let flag = true;
    params.subs_plan_item = list?.map((item, index) => {
      if (!item.delete) {
        licenseColumns?.forEach((column) => {
          if (column?.require) {
            if (column.key === "item_alert_value" && item.alert_value) {
              return;
            }
            if (
              column.key === "item_auth_value" &&
              item.dic_item_name === "授权截止日期"
            ) {
              return;
            }
            // if (item.type === "more_select" && column.key === "item_auth_value") {
            //   const value = item[column.key] || {};
            //   if((value[item.menu_key] || [])?.length === 0){
            //     flag = false;
            //   }
            //   if((value[item.fun_key] || [])?.length === 0){
            //     flag = false;
            //   }
            // }
            if (item.type === "more_select" && column.key === "item_auth_value"){
              return
            }
            if (
              item[column.key] === null ||
              item[column.key] === undefined ||
              item[column.key] === ""
            ) { 
              flag = false;
            }
          }
        });
      }
      if (item.type === "more_select") {
        let value = item.item_auth_value || ""
        if(!value){
          let obj = {};
          obj[item.fun_key] = [];
          obj[item.menu_key] = [];
          value = obj
        }
        item.item_auth_value = JSON.stringify(value);
      }
      return {
        ...item,
        seq: index + 1,
      };
    });
    if (!flag) {
      message.info("请填写完整订阅许可项");
      setCheckStatus(true);
      return;
    }
    let api = apis?.addPlanList;
    if (type === "修改") {
      params.subs_plan_id = (selectItem.ver_info || {})?.subs_plan_id;
      params.subs_plan_ver_id = (selectItem.ver_info || {})?.subs_plan_ver_id;
      api = apis?.updatePlanList;
    }
    console.log("---------", params, getDeleteList());
    api(params).then((res) => {
      console.log(res);
      if (res.code === 200) {
        // message.success("创建成功");
        setEditShow(false);
        getDataFunc();
      } else {
        message.error(res.message);
      }
    });
  };

  // if(true){
  //   return <div className="editHtmlContent">
  //     <Spin />
  //   </div>
  // }

  return (
    <div className="editHtmlContent">
      <Spin spinning={loading}>
        <div className="itemTitle">
          <span></span>
          {type}订阅方案
        </div>
        <div className="itemTitle2">
          <span>
            01<i></i>
          </span>
          订阅信息
        </div>
        {planList?.length >= 2 && (
          <Select
            className="verSelect"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={verId}
            style={{
              width: 200,
            }}
            onChange={(value) => {
              changeVer(value);
            }}
            options={planList}
          />
        )}
        {/* 订阅信息 */}
        <div className="subscriptionItemsContent">
          <div className="subscriptionItem">
            <i>*</i>
            <span className="itemLabel">产品名称</span>
            {type === "新增" ? (
              <Select
                style={{
                  width: "500px",
                }}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setProductId(value);
                  setTemplateId(null);
                  setLicenseItemsList([]);
                  setTimeout(() => {
                    getTemplateList();
                  }, 100);
                }}
                value={productId}
                options={productList?.map((_) => ({
                  value: _.prd_id,
                  label: _.prd_name,
                }))}
                placeholder="请选择产品名称"
              />
            ) : (
              <div>
                {
                  ((showData?.subs_plan_info || {})?.product_info || {})
                    ?.prd_name
                }
              </div>
            )}
          </div>
          <div className="subscriptionItem">
            <i>*</i>
            <span className="itemLabel">版本类型</span>
            {type === "新增" ? (
              <Select
                style={{
                  width: "500px",
                }}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setVersionType(value);
                }}
                value={versionType}
                options={versionTypeList}
                placeholder="请选择版本类型"
              />
            ) : (
              <div>{(showData?.subs_plan_info || {})?.version_type_name}</div>
            )}
          </div>
          <div className="subscriptionItem">
            <i>*</i>
            <span className="itemLabel">订阅方案编码</span>
            {type === "新增" ? (
              <Input
                style={{ width: "500px" }}
                placeholder="请输入订阅方案编码"
                value={planCode}
                maxLength={100}
                onChange={(e) => {
                  setPlanCode(e.target.value);
                }}
              ></Input>
            ) : (
              <div style={{ width: "calc(100% - 60px)" }}>
                <Paragraph
                  style={{ marginBottom: "0px" }}
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: (showData?.subs_plan_info || {})?.subs_plan_code,
                    },
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {(showData?.subs_plan_info || {})?.subs_plan_code}
                  </div>
                </Paragraph>
              </div>
            )}
          </div>
          <div className="subscriptionItem">
            <i>*</i>
            <span className="itemLabel">订阅方案名称</span>
            {type === "查看" ? (
              <div style={{ width: "calc(100% - 60px)" }}>
                <Paragraph
                  style={{ marginBottom: "0px" }}
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: (showData?.ver_info || {})?.subs_plan_name,
                    },
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {(showData?.ver_info || {})?.subs_plan_name}
                  </div>
                </Paragraph>
              </div>
            ) : (
              <Input
                style={{ width: "500px" }}
                placeholder="请输入订阅方案名称"
                value={planName}
                maxLength={100}
                onChange={(e) => {
                  setPlanName(e.target.value);
                }}
              ></Input>
            )}
          </div>
          <div className="subscriptionItem">
            <i>*</i>
            <span className="itemLabel">产品配置模板</span>
            {type === "查看" ? (
              <div style={{ width: "calc(100% - 60px)" }}>
                <Paragraph
                  style={{ marginBottom: "0px" }}
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: (showData?.subs_plan_info || {})?.prd_co_name,
                    },
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {(showData?.subs_plan_info || {})?.prd_co_name}
                  </div>
                </Paragraph>
              </div>
            ) : (
              <Select
                style={{
                  width: "500px",
                }}
                notFoundContent={
                  getProductId() ? undefined : (
                    <div
                      style={{
                        width: "100%",
                        padding: "10px",
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.45)",
                      }}
                    >
                      请先选择产品名称
                    </div>
                  )
                }
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setCheckStatus(false);
                  setTemplateId(value);
                  getLicenseItemsList(value);
                }}
                value={templateId}
                options={templateList}
                placeholder="请选择产品配置模板"
              />
            )}
          </div>
          <div className="subscriptionItem">
            <i></i>
            <span className="itemLabel">默认订阅时长</span>
            {type === "查看" ? (
              <div style={{ width: "calc(100% - 60px)" }}>
                <Paragraph
                  style={{ marginBottom: "0px" }}
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: (showData?.ver_info || {})?.subs_default_dur
                        ? (showData?.ver_info || {})?.subs_default_dur +
                          (showData?.ver_info || {})?.subs_dur_unit
                        : "",
                    },
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {(showData?.ver_info || {})?.subs_default_dur
                      ? (showData?.ver_info || {})?.subs_default_dur +
                        (showData?.ver_info || {})?.subs_dur_unit
                      : ""}
                  </div>
                </Paragraph>
              </div>
            ) : (
              <>
                <Input
                  style={{ width: "calc(500px - 120px)", marginRight: "10px" }}
                  placeholder="请输入默认订阅时长"
                  value={subsDefaultDur}
                  onChange={(e) => {
                    let value = e.target.value?.replace(/\D/g, "")?.slice(0, 3);
                    value = value ? parseInt(value)?.toString() : "";
                    setSubsDefaultDur(value);
                  }}
                ></Input>
                <Select
                  style={{
                    width: "110px",
                  }}
                  onChange={(value) => {
                    setSubsDurUnit(value);
                  }}
                  value={subsDurUnit}
                  options={[
                    {
                      label: "天",
                      value: "天",
                    },
                    {
                      label: "月",
                      value: "月",
                    },
                    {
                      label: "年",
                      value: "年",
                    },
                  ]}
                />
              </>
            )}
          </div>
          <div className="subscriptionItem" style={{ width: "100%" }}>
            <i></i>
            <span className="itemLabel">订阅方案描述</span>
            {type === "查看" ? (
              <div style={{ width: "calc(100% - 60px)" }}>
                <Paragraph
                  style={{ marginBottom: "0px" }}
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: (showData?.ver_info || {})?.subs_plan_desc,
                    },
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {(showData?.ver_info || {})?.subs_plan_desc}
                  </div>
                </Paragraph>
              </div>
            ) : (
              <Input
                style={{ width: "500px" }}
                placeholder="请输入订阅方案描述"
                maxLength={100}
                value={planDesc}
                onChange={(e) => {
                  setPlanDesc(e.target.value);
                }}
              ></Input>
            )}
          </div>
          {type === "查看" && (
            <div className="subscriptionItem">
              <i></i>
              <span className="itemLabel">创建人</span>
              <div>{(showData?.ver_info || {})?.user_name}</div>
            </div>
          )}
          {type === "查看" && (
            <div className="subscriptionItem">
              <i></i>
              <span className="itemLabel">创建时间</span>
              <div>{(showData?.ver_info || {})?.created_time}</div>
            </div>
          )}
        </div>
        {/* ---- */}
        <div className="itemTitle2">
          <span>
            02<i></i>
          </span>
          订阅许可项
        </div>
        {/* 订阅许可项 */}
        <div className="subscriptionLicenseItemsContent">
          <div className="subscriptionLicenseItemsHeader">
            {licenseColumns?.map((item, index) => (
              <div
                className="headerItem"
                style={{ width: item.width }}
                key={index}
              >
                <i>{item?.require ? "*" : ""}</i>
                {item.name}
              </div>
            ))}
            {<div className="addBox"></div>}
          </div>
          {licenseItemsList?.length > 0 || type === "查看" ? (
            info?.map((item, index) => (
              <div className="subscriptionLicenseItemLine" key={index}>
                {licenseColumns?.map((column) => (
                  <div
                    className="itemBox"
                    style={{ width: column.width }}
                    key={column.key}
                  >
                    {initItem(item, column, index)}
                  </div>
                ))}
                {type !== "查看" &&
                  (item.require && !item.isNew ? (
                    <div style={{ width: "14px" }}></div>
                  ) : (
                    <MinusCircleFilled
                      className="deleteIcon"
                      onClick={() => {
                        MessageModal({
                          type: "warning",
                          description: "确定要删除此条数据吗？",
                          // title: "删除",
                          onOk: () => {
                            if (!item.isNew) {
                              setDeleteList((prev) => {
                                return [...prev, item];
                              });
                            }
                            setInfo((prev) => {
                              prev = prev.filter(
                                (_, _index) => index !== _index
                              );
                              return [...prev];
                            });
                          },
                        });
                      }}
                    />
                  ))}
              </div>
            ))
          ) : (
            <div className="subscriptionLicenseEmptyItem">
              选择产品配置模板后配置许可项
            </div>
          )}
          {type !== "查看" && licenseItemsList?.length > 0 && (
            <div
              className="subscriptionLicenseAddIcon"
              onClick={() => {
                //有选项但是最后一项没填写 禁止添加
                if (
                  info?.length > 0 &&
                  !info?.at(-1)?.subs_plan_item_type_code
                ) {
                  return;
                }
                setInfo((prev) => {
                  return [...prev, { isNew: true }];
                });
              }}
            >
              新增许可项 <PlusCircleFilled />
            </div>
          )}
        </div>
        {/* ---- */}
        <div className="subscriptionLicenseBtnBox">
          <Button
            onClick={() => {
              setEditShow(false);
            }}
          >
            {type === "查看" ? "关闭" : "取消"}
          </Button>
          {type !== "查看" && (
            <Button
              style={{ marginLeft: "24px" }}
              type="primary"
              onClick={createFunc}
            >
              {type === "修改" ? "修改" : "新增"}
            </Button>
          )}
        </div>
      </Spin>
    </div>
  );
};
export default EditHtml;
