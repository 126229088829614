import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, setToken, getRedirectUrl } from "../../unit";
import { useLocation } from "react-router-dom";

import Api from "../../apis/index";
const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goRouter = () => {
    if (getRedirectUrl()) {
      window.location.href = getRedirectUrl();
    } else {
      navigate("/subscriptionManagement");
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search); // 获取地址栏参数
    const token = params.get("token");
    //本地开发环境 token在地址上带过来
    if (token) {
      setToken(token);
      goRouter();
      return;
    }
    //登录时 token 存在一级域名下
    if (getToken()) {
      goRouter();
      return;
    }
    

    //传统的 ticket 方式登录
    let url = document.location.href;
    let ticket = "";
    if (/ticket=(ST-[^#/?]+)/.test(url)) {
      ticket = /ticket=(ST-[^#/?]+)/.exec(url)[1];
    }
    Api.loginFunc(ticket, url).then((res) => {
      console.log("🚀 ~ file: index.jsx:20 ~ Api.loginFunc ~ res:", res);
      if (res.data.status === "redirect") {
        window.location.href = res.data.login_url;
      } else if (res.data.status === "login") {
        const token = res.data.token;
        setToken(token);
        goRouter();
      }
    });
  }, []);

  return <div></div>;
};

export default Login;
